import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Input } from '../../components/Form/Input/Input';

interface RequestsSearchProps {
  search: string;
  setSearch: (next: string) => void;
}

export const RequestsSearch: React.FC<RequestsSearchProps> = ({ search, setSearch }: RequestsSearchProps) => (
    <div className="RequestsSearch">
      <Input
          id="inquiry-request-search"
          label="Suche"
          value={ search }
          onChange={ setSearch }
          placeholder={ 'Durchsuche deine Anfragen...' }
          prefixIcon={
            <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={ faSearch }/>
                  </span>
          }
      />
    </div>
);