import { faAddressBook, faEnvelope, faPhone, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Button } from '../../components/Form/Button/Button';
import { ConfirmationButton } from '../../components/Form/Button/ConfirmationButton';
import { Checkbox } from '../../components/Form/Checkbox/Checkbox';
import { Input } from '../../components/Form/Input/Input';
import { UnsortedList } from '../../components/Layout/List/UnsortedList';
import { Section } from '../../components/Layout/Section/Section';
import { Row } from '../../components/Table/Row';
import { Inquiry, isAdult } from '../../data/inquiry.interface';
import { Screen, useWidth } from '../../hooks/useWidth';
import { useHttp } from '../../http/http';
import { useInquiries } from '../../zustand/inquiries.store';
import { useNotifications } from '../../zustand/notifications.store';
import './InquiryRow.scss';

interface InquiryRowProps {
  inquiry: Inquiry;
}

export const InquiryRow: React.FC<InquiryRowProps> = ({ inquiry }: InquiryRowProps) => {
  const { GET, POST, DELETE } = useHttp();
  const { screen, isMobile } = useWidth();

  const download = useInquiries(state => state.download);
  const showErrorNotification = useNotifications(state => state.showErrorNotification);
  const showSuccessNotification = useNotifications(state => state.showSuccessNotification);

  const assign = useInquiries(state => state.assign);
  const remove = useInquiries(state => state.remove);
  const requestAcceptAgb = useInquiries(state => state.requestAcceptAgb);
  const deleteInquiry = useInquiries(state => state.delete);

  const createdAt = moment(inquiry.createdAt);

  const Actions: React.FC = () => {
    const downloadVCard = () => {
      download(GET, inquiry, {
        onSuccess: (blob => {
          const vcard = new Blob([blob], { type: 'text/vcard' });
          const url = window.URL.createObjectURL(vcard);
          const link = document.createElement('a');
          link.href = url;
          //link.setAttribute('download', `${ inquiry.firstname }-${ inquiry.lastname }.vcf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        }),
        onError: (errorMessage) => {
          showErrorNotification(errorMessage);
        },
      });
    };

    const doAssign = () => {
      assign(POST, inquiry.id, {
        onSuccess: () => {

        },
        onError: () => showErrorNotification('Es ist ein Fehler aufgetreten.'),
      });
    };

    const doRemove = () => {
      remove(POST, inquiry.id, {
        onSuccess: () => {

        },
        onError: () => showErrorNotification('Es ist ein Fehler aufgetreten.'),
      });
    };

    const doDelete = () => {
      deleteInquiry(DELETE, inquiry.id, {
        onSuccess: () => {

        },
        onError: () => showErrorNotification('Es ist ein Fehler aufgetreten.'),
      });
    };

    return (
        <span className="actions">
          <Button onClick={ downloadVCard }>
            <span className={ classNames('icon', { 'mr-2': isMobile }) }>
              <FontAwesomeIcon icon={ faAddressBook }/>
            </span>
            { isMobile && 'Kontakt' }
          </Button>
          { inquiry.assigned
              ? <Button onClick={ doRemove }>Zuweisung aufheben</Button>
              : <Button onClick={ doAssign }>Zuweisen</Button> }
          <ConfirmationButton
              onClick={ doDelete }
              confirmTitle="Diese Anfrage löschen?"
              confirmContent={ <>
                <p className="block">Die Anfrage
                  von <strong>{ inquiry.firstname } { inquiry.lastname }</strong> verschwindet dann aus deiner Ansicht.
                </p>
                <p className="block">Die Daten bleiben bis zum Ende der Aufbewahrungsfrist auf dem Server
                  gespeichert.</p>
              </> }
              confirmLabel="Anfrage löschen"
          >
            <span className={ classNames('icon', { 'mr-2': isMobile }) }>
              <FontAwesomeIcon icon={ faTrash }/>
            </span>
            { isMobile && 'Anfrage löschen' }
          </ConfirmationButton>
        </span>
    );
  };

  const ExpandableContent: React.FC = () => {

    const doRequestAcceptAgb = () => {
      requestAcceptAgb(POST, inquiry.id, {
        onSuccess: () => showSuccessNotification('Die E-Mail wurde verschickt.'),
        onError: () => showErrorNotification('Es ist ein Fehler aufgetreten. Die E-Mail wurde wahrscheinlich nicht verschickt'),
      });
    };

    return (
        <td colSpan={ 999 }>
          <div className="block is-flex">
            <span className="icon mr-2">
              <FontAwesomeIcon icon={ faEnvelope }/>
            </span>
            <a href={ 'mailto:' + inquiry.email } target="_blank" rel="noreferrer">
              { inquiry.email }
            </a>
          </div>
          <div className="block is-flex">
            <span className="icon mr-2">
              <FontAwesomeIcon icon={ faPhone }/>
            </span>
            <a href={ 'tel:' + inquiry.phone } target="_blank" rel="noreferrer">
              { inquiry.phone }
            </a>
          </div>
          {
              screen === Screen.MOBILE && (<Actions/>)
          }
          <div className="block">
            <Input
                label="Wünsche und Anmerkungen"
                value={ inquiry.notes }
                type="textarea"
                rows={ 5 }
                disabled
            />
          </div>

          <Section title="Checkliste">
            {
              inquiry.checks.map(it => (
                  <Checkbox key={ it.text } checked={ it.checked } disabled>
                    { it.text }
                  </Checkbox>
              ))
            }
          </Section>

          <Section title="Lieblingszeiten">
            {
              inquiry.favoriteCourseOptions.map(it => (
                  <Checkbox key={ it.text } checked={ it.checked } disabled>
                    { it.text }
                  </Checkbox>
              ))
            }
          </Section>

          <Section title="Zustimmungen">
            <Checkbox checked={ !!inquiry.allowSaveData } disabled>
              Zustimmung zur
              Datenverarbeitung: <>{ inquiry.allowSaveData ? moment(inquiry.allowSaveData).format('DD.MM.YYYY HH:mm') : 'keine Zustimmung' }</>
            </Checkbox>
            <Checkbox checked={ !!inquiry.allowContact } disabled>
              Zustimmung zum
              Kontaktieren: <>{ inquiry.allowContact ? moment(inquiry.allowContact).format('DD.MM.YYYY HH:mm') : 'keine Zustimmung' }</>
            </Checkbox>
            <div className="accept-agb">
              <Checkbox checked={ !!inquiry.acceptedAgb } disabled>
                Zustimmung zu den
                AGB: <>{ inquiry.acceptedAgb ? moment(inquiry.acceptedAgb).format('DD.MM.YYYY HH:mm') : 'keine Zustimmung' }</>
              </Checkbox>
              { !inquiry.acceptedAgb && (
                  <Button className="mx-2" onClick={ doRequestAcceptAgb }>per E-Mail um Zustimmung bitten</Button>
              ) }
            </div>
          </Section>

          <div className="block">
            <p>
              Anfrage vom: { createdAt.isValid() ? createdAt.format('DD.MM.YY') : '-' }
            </p>
          </div>
        </td>
    );
  };

  const adult = isAdult(inquiry.course);
  const dateOfBirth = adult ? moment(inquiry.dateOfBirth) : moment(inquiry.child.dateOfBirth);

  if (isMobile) {
    return (
        <Row expandableContent={ <ExpandableContent/> } className={ classNames({ 'assigned': inquiry.assigned }) }>
          <td>
            <div>{ inquiry.firstname } { inquiry.lastname } { adult ? '(' + moment().diff(dateOfBirth, 'years', false) + ' J.)' : '' }</div>
            { !adult && (
                <div
                    className="has-text-weight-bold">{ inquiry.child.firstname } { inquiry.child.lastname } ({ moment().diff(dateOfBirth, 'years', false) })
                </div>
            ) }
          </td>
        </Row>
    );
  } else {
    return (
        <Row expandableContent={ <ExpandableContent/> } className={ classNames({ 'assigned': inquiry.assigned }) }>
          <td>{ inquiry.firstname } { inquiry.lastname }</td>
          { !adult && <td>{ inquiry.child.firstname } { inquiry.child.lastname }</td> }
          <td>{ dateOfBirth.isValid()
              ? (
                  <>
                    { dateOfBirth.format('DD.MM.YY') } ({ moment().diff(dateOfBirth, 'years', false) } J.)
                  </>
              )
              : '?' }
          </td>
          <td>
            <Checkbox
                checked={ !!inquiry.acceptedAgb }
                disabled
            />
          </td>
          <td>
            <Actions/>
          </td>
        </Row>
    );
  }
};