export const courses = ['Wassergewöhnung', 'Seepferdchen', 'Bronze', 'Silber', 'Gold', 'Erwachsene'] as const;
export type Course = typeof courses[number];

export const isAdult = (c: Course) => c === 'Erwachsene';

export interface InquiryRequest {
  course: Course;
  firstname: string;
  lastname: string;
  dateOfBirth: string;
  email: string;
  phone: string;
  notes: string;
  child: Child;
  checks?: Check[];
  favoriteCourseOptions?: Check[];
  allowSaveData: boolean;
  allowContact: boolean;
  acceptedAgb: boolean;
}

export interface Inquiry {
  id: string;
  course: Course;
  firstname: string;
  lastname: string;
  dateOfBirth: string;
  email: string;
  phone: string;
  child: Child;
  notes: string;
  createdAt: string;
  checks: Check[];
  favoriteCourseOptions: CourseOption[];
  allowSaveData: string;
  allowContact: string;
  acceptedAgb?: string;
  assigned: boolean;
}

export interface Child {
  firstname: string;
  lastname: string;
  dateOfBirth: string;
}

export interface Check {
  text: string;
  checked: boolean;
}

export interface CourseOption {
  text: string;
  checked: boolean;
}