import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '../../components/Layout/Container/Container';
import { UnsortedList } from '../../components/Layout/List/UnsortedList';
import { Title } from '../../components/Layout/Title/Title';
import { Routes } from '../../constants/Routes';
import { useWidth } from '../../hooks/useWidth';
import { PageWithNavigation } from '../PageWithNavigation';
import { OfferBox } from './OfferBox/OfferBox';
import './OffersPage.scss';
import { OfferListItem } from './OfferListItem/OfferListItem';

export const OffersPage: React.FC = () => {
  const { screen, isMobile } = useWidth();
  
  return (
      <PageWithNavigation>
        <Helmet title="Unsere Schwimmkurse in Winsen">
          <meta name="description" content="Seepferdchen, Bronze, Silber, Gold oder der erste Kontakt mit dem Wasser für die ganz kleinen Kinder. Finde deinen passenden Schwimmkurs in Winsen Luhe, im Freizeitbad-die-Insel." />
        </Helmet>
        { isMobile ? <MobileOffersPage /> : <DesktopOffersPage /> }
      </PageWithNavigation>
  );
};

const MobileOffersPage = () => (
    <Container>
      <Title
          className="title-box"
          title="Unsere Schwimm&shy;kurse"
          subtitle="Finde deinen passenden Schwimmkurs in Winsen Luhe, im Freizeitbad-die-Insel."
      />
      <UnsortedList className="p-0">
        <OfferListItem
            title="Wasser&shy;gewöhnung"
            subtitle="ab 3,5 Jahren"
            offerPath={ Routes.OffersRobby.path }
            imgSrc="/img/abzeichen/wassergewoehnung_400x400.png"
            avifSrc="/img/abzeichen/wassergewoehnung_400x400.avif"
        />
        <OfferListItem
            title="Seepferdchen"
            subtitle="ab 5 Jahren"
            offerPath={ Routes.OffersSeepferdchen.path }
            imgSrc="/img/abzeichen/seepferdchen_400x400.png"
            avifSrc="/img/abzeichen/seepferdchen_400x400.avif"
        />
        <OfferListItem
            title="Bronze"
            subtitle="ab 6 Jahren"
            offerPath={ Routes.OffersBronze.path }
            imgSrc="/img/abzeichen/bronze_400x400.png"
            avifSrc="/img/abzeichen/bronze_400x400.avif"
        />
        <OfferListItem
            title="Silber"
            subtitle="ab 7 Jahren"
            offerPath={ Routes.OffersSilver.path }
            imgSrc="/img/abzeichen/silber_400x400.png"
            avifSrc="/img/abzeichen/silber_400x400.avif"
        />
        <OfferListItem
            title="Gold"
            subtitle="ab 9 Jahren"
            offerPath={ Routes.OffersGold.path }
            imgSrc="/img/abzeichen/gold_400x400.png"
            avifSrc="/img/abzeichen/gold_400x400.avif"
        />
        <OfferListItem
            title="für Erwachsene"
            subtitle="Anfänger bis Profis"
            offerPath={ Routes.OffersAdult.path }
            imgSrc="/img/logo.png"
            avifSrc="/img/logo.avif"
        />
      </UnsortedList>
    </Container>
)

const DesktopOffersPage = () => (
    <Container>
      <div className="offers">
        <Title
            className="title-box p-4"
            title="Unsere Schwimm&shy;kurse"
            subtitle="Finde deinen passenden Schwimmkurs in Winsen Luhe, im Freizeitbad-die-Insel."
        />
        <OfferBox
            title="Wasser&shy;gewöhnung"
            subtitle="ab 3,5 Jahren"
            offerPath={ Routes.OffersRobby.path }
            imgSrc="/img/abzeichen/wassergewoehnung_400x400.png"
            avifSrc="/img/abzeichen/wassergewoehnung_400x400.avif"
        />
        <OfferBox
            title="Seepferdchen"
            subtitle="ab 5 Jahren"
            offerPath={ Routes.OffersSeepferdchen.path }
            imgSrc="/img/abzeichen/seepferdchen_400x400.png"
            avifSrc="/img/abzeichen/seepferdchen_400x400.avif"
        />
        <OfferBox
            title="Bronze"
            subtitle="ab 6 Jahren"
            offerPath={ Routes.OffersBronze.path }
            imgSrc="/img/abzeichen/bronze_400x400.png"
            avifSrc="/img/abzeichen/bronze_400x400.avif"
        />
        <OfferBox
            title="Silber"
            subtitle="ab 7 Jahren"
            offerPath={ Routes.OffersSilver.path }
            imgSrc="/img/abzeichen/silber_400x400.png"
            avifSrc="/img/abzeichen/silber_400x400.avif"
        />
        <OfferBox
            title="Gold"
            subtitle="ab 9 Jahren"
            offerPath={ Routes.OffersGold.path }
            imgSrc="/img/abzeichen/gold_400x400.png"
            avifSrc="/img/abzeichen/gold_400x400.avif"
        />
        <OfferBox
            title="für Erwachsene"
            subtitle="Anfänger bis Profis"
            offerPath={ Routes.OffersAdult.path }
            imgSrc="/img/logo.png"
            avifSrc="/img/logo.avif"
        />
      </div>
    </Container>
)