export interface Route {
  path: string,
  title?: string,
}

export const Routes: { [key: string]: Route } = {
  Home: {
    path: '/'
  },
  Agb: {
    path: '/agb',
    title: 'Allgemeine Geschäftsbedingungen (AGB)'
  },
  AcceptAgb: {
    path: '/agb/:id',
    title: 'Allgemeine Geschäftsbedingungen (AGB) akzeptieren'
  },
  Offers: {
    path: '/angebot',
    title: 'Schwimmkurse'
  },
  OffersRobby: {
    path: '/angebot/wassergewoehnungskurs',
    title: 'Wassergewöhnungskurs'
  },
  OffersSeepferdchen: {
    path: '/angebot/seepferdchenkurs',
    title: 'Seepferdchenkurs'
  },
  OffersBronze: {
    path: '/angebot/bronzekurs',
    title: 'Bronzekurs'
  },
  OffersSilver: {
    path: '/angebot/silberkurs',
    title: 'Silberkurs'
  },
  OffersGold: {
    path: '/angebot/goldkurs',
    title: 'Goldkurs'
  },
  OffersAdult: {
    path: '/angebot/erwachsene',
    title: 'Schwimmen für Erwachsene'
  },
  OfferAccepted: {
    path: '/anfrage-erhalten',
    title: 'Anfrage erhalten'
  },
  Requests: {
    path: '/anfragen',
    title: 'Anfragen'
  },
  ManualRequest: {
    path: '/anfragen/manuell-erfassen',
    title: 'Anfrage manuell erfassen'
  },
  FAQ: {
    path: '/faq',
    title: 'FAQ'
  },
  Impressum: {
    path: '/impressum',
    title: 'Impressum'
  },
  Datenschutz: {
    path: '/datenschutz',
    title: 'Datenschutzhinweise'
  },
  NotFound: {
    path: '*',
    title: 'Seite nicht gefunden'
  }
}
