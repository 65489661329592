import { catchError, EMPTY, finalize, map, take, tap } from 'rxjs';
import { create } from 'zustand';
import { Inquiry, InquiryRequest } from '../data/inquiry.interface';
import { HTTP_DELETE, HTTP_GET, HTTP_POST } from '../http/http';

interface CallbackActions<T> {
  onSuccess?: (d: T) => void;
  onError?: (errorMessage: string) => void;
}

interface InquiriesStore {
  allLoading: boolean;
  all: Inquiry[];
  loadAll: (GET: HTTP_GET) => void;
  allError?: string;

  saveLoading: boolean;
  saveError?: string;
  save: (POST: HTTP_POST, inquiry: InquiryRequest, actions: CallbackActions<void>) => void;

  downloadPending: boolean;
  downloadError?: string;
  download: (GET: HTTP_GET, inquiry: Inquiry, actions: CallbackActions<Blob>) => void;

  downloadExcel: (GET: HTTP_GET, actions: CallbackActions<Blob>) => void;

  assign: (POST: HTTP_POST, inquiryId: string, actions: CallbackActions<Inquiry>) => void;
  remove: (POST: HTTP_POST, inquiryId: string, actions: CallbackActions<Inquiry>) => void;
  requestAcceptAgb: (POST: HTTP_POST, inquiryId: string, actions: CallbackActions<void>) => void;
  delete: (DELETE: HTTP_DELETE, inquiryId: string, actions: CallbackActions<void>) => void;
}

export const useInquiries = create<InquiriesStore>((set, getState) => ({
  allLoading: false,
  all: [],
  allError: undefined,
  loadAll: (GET: HTTP_GET) => {
    set({ allLoading: true });
    GET<Inquiry[]>('/api/inquiries').pipe(
        take(1),
        tap(all => {
          set({
            allError: undefined,
            all: all,
          });
        }),
        catchError(err => {
          console.error(err);
          set({ allError: 'Deine Anfragen konnte leider nicht geladen werden.' });
          return EMPTY;
        }),
        finalize(() => set({ allLoading: false })),
    ).subscribe();
  },

  saveLoading: false,
  saveError: undefined,
  save: (POST: HTTP_POST, inquiry: InquiryRequest, actions: CallbackActions<void> = {}) => {
    set({ saveLoading: true });
    POST<InquiryRequest, Inquiry>('/api/inquiries', inquiry).pipe(
        take(1),
        tap((i) => {
          set({ saveError: undefined });
          actions.onSuccess?.();
        }),
        catchError(err => {
          console.error(err);
          const errorMessage = 'Deine Anfrage konnte leider nicht verarbeitet werden.';
          set({ saveError: errorMessage });
          actions.onError?.(errorMessage);
          return EMPTY;
        }),
        finalize(() => set({ saveLoading: false })),
    ).subscribe();
  },

  downloadPending: false,
  downloadError: undefined,
  download: (GET: HTTP_GET, inquiry: Inquiry, actions: CallbackActions<Blob> = {}) => {
    set({ downloadPending: true });

    GET<ArrayBuffer>(`/api/inquiries/${ inquiry.id }/vcard`, { accept: 'application/octet-stream' }).pipe(
        take(1),
        map(data => new Blob([data])),
        tap((blob) => {
          set({ downloadError: undefined });
          actions.onSuccess?.(blob);
        }),
        catchError(err => {
          console.error(err);
          const errorMessage = 'Der Kontakt konnte nicht heruntergeladen werden.';
          set({ downloadError: errorMessage });
          actions.onError?.(errorMessage);
          return EMPTY;
        }),
        finalize(() => set({ downloadPending: false })),
    ).subscribe();
  },

  downloadExcel: (GET: HTTP_GET, actions: CallbackActions<Blob> = {}) => {
    GET<ArrayBuffer>(`/api/inquiries/excel`, { accept: 'application/octet-stream' }).pipe(
        take(1),
        map(data => new Blob([data])),
        tap((blob) => {
          set({ downloadError: undefined });
          actions.onSuccess?.(blob);
        }),
        catchError(err => {
          console.error(err);
          const errorMessage = 'Der Kontakt konnte nicht heruntergeladen werden.';
          set({ downloadError: errorMessage });
          actions.onError?.(errorMessage);
          return EMPTY;
        }),
    ).subscribe();
  },

  assign: (POST: HTTP_POST, inquiryId: string, actions: CallbackActions<Inquiry>) => {
    POST<null, Inquiry>(`api/inquiries/${ inquiryId }/assign`, null).pipe(
        take(1),
        tap((updated) => {
          set({ all: updateById(getState().all, updated) });
          actions.onSuccess?.(updated);
        }),
        catchError(err => {
          console.error(err);
          actions.onError?.(err);
          return EMPTY;
        }),
    ).subscribe();
  },
  remove: (POST: HTTP_POST, inquiryId: string, actions: CallbackActions<Inquiry>) => {
    POST<null, Inquiry>(`api/inquiries/${ inquiryId }/remove`, null).pipe(
        take(1),
        tap((updated) => {
          set({ all: updateById(getState().all, updated) });
          actions.onSuccess?.(updated);
        }),
        catchError(err => {
          console.error(err);
          actions.onError?.(err);
          return EMPTY;
        }),
    ).subscribe();
  },
  requestAcceptAgb: (POST: HTTP_POST, inquiryId: string, actions: CallbackActions<void>) => {
    POST<null, Inquiry>(`api/inquiries/${ inquiryId }/agb/request-accept`, null).pipe(
        take(1),
        tap((updated) => {
          actions.onSuccess?.();
        }),
        catchError(err => {
          console.error(err);
          actions.onError?.(err);
          return EMPTY;
        }),
    ).subscribe();
  },
  delete: (DELETE: HTTP_DELETE, inquiryId: string, actions: CallbackActions<void>) => {
    DELETE(`api/inquiries/${ inquiryId }`).pipe(
        take(1),
        tap(() => {
          console.warn('deleted', inquiryId);
          const next = getState().all.filter(it => it.id !== inquiryId);
          console.warn('next', next);
          set({ all: next });
          actions.onSuccess?.();
        }),
        catchError(err => {
          console.error(err);
          actions.onError?.(err);
          return EMPTY;
        }),
    ).subscribe();
  },
}));

const updateById = (all: Inquiry[], updated: Inquiry): Inquiry[] => {
  const next = [...all];
  const idx = all.findIndex(it => it.id === updated.id);
  if (idx >= 0) {
    next[idx] = updated;
  }
  return next;
};