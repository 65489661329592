import React, { ReactNode } from 'react';
import { Container } from '../Container/Container';


interface HeroProps {
  title?: ReactNode;
  subtitle?: string;
  children?: ReactNode;
}

export const Hero: React.FC<HeroProps> = ({ title, subtitle, children }: HeroProps) => (
    <div className="Hero hero is-primary-light">
      <Container>
        <div className="hero-body px-0">
          <h2 className="title">{ title }</h2>
          { subtitle && <h3 className="subtitle">{ subtitle }</h3> }
          { children }
        </div>
      </Container>
    </div>
);