import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '../../components/Form/Button/Button';
import { Container } from '../../components/Layout/Container/Container';
import { Tab } from '../../components/Layout/Tabs/Tab';
import { Tabs } from '../../components/Layout/Tabs/Tabs';
import { Title } from '../../components/Layout/Title/Title';
import { Routes } from '../../constants/Routes';
import { Course, courses, Inquiry } from '../../data/inquiry.interface';
import { useHttp } from '../../http/http';
import { groupBy } from '../../utils/groupBy';
import { useInquiries } from '../../zustand/inquiries.store';
import { useNotifications } from '../../zustand/notifications.store';
import { PageWithNavigation } from '../PageWithNavigation';
import './RequestsPage.scss';
import { RequestsSearch } from './RequestsSearch';
import { RequestsTable } from './RequestsTable';

export const RequestsPage: React.FC = () => {
  const { GET } = useHttp();
  const { allLoading, allError, loadAll, all } = useInquiries();
  const [search, setSearch] = useState('');
  const downloadExcel = useInquiries(state => state.downloadExcel);
  const showErrorNotification = useNotifications(state => state.showErrorNotification);

  useEffect(() => {
    loadAll(GET);
  }, []);

  const allByCourse: Map<Course, Inquiry[]> = useMemo(() => {
    const sorted = all.sort((i1, i2) => i1.createdAt.localeCompare(i2.createdAt));
    return groupBy(sorted, inquiry => inquiry.course);
  }, [all]);

  const allByCourseFiltered: Map<Course, Inquiry[]> = useMemo(() => {
    if (search === '') {
      return allByCourse;
    } else {
      const s = search.toLowerCase();
      const sorted = all.sort((i1, i2) => i1.createdAt.localeCompare(i2.createdAt));
      const filtered = sorted.filter(it =>
          it.firstname.toLowerCase().startsWith(s)
          || it.lastname.toLowerCase().startsWith(s)
          || it.child.firstname.toLowerCase().startsWith(s)
          || it.child.lastname.toLowerCase().startsWith(s));
      return groupBy(filtered, inquiry => inquiry.course);
    }
  }, [all, allByCourse, search]);

  const downloadExcelFile = () => {
    downloadExcel(GET, {
      onSuccess: (blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `alle-anfragen.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }),
      onError: (errorMessage) => {
        showErrorNotification(errorMessage);
      },
    });
  };

  return (
      <PageWithNavigation>
        <Helmet title={ Routes.Requests.title }>
          <meta name="robots" content="noindex, nofollow"/>
        </Helmet>

        <Container>
          <Title title="Anfragen" subtitle="Hier siehst du, wer einen Kurs machen möchte"/>

          <div className="actions">
            <RequestsSearch search={ search } setSearch={ setSearch }/>
            <Button href={ Routes.ManualRequest.path } color="primary">Anfrage manuell erfassen</Button>
            <Button onClick={ downloadExcelFile }>Excel</Button>
          </div>
          <Tabs>
            {
              courses.map(course => {
                const inquiries = allByCourseFiltered?.get(course) ?? [];
                return (
                    <Tab title={ `${ course } (${ inquiries.length })` } key={ course }>
                      <RequestsTable
                          course={ course }
                          loading={ allLoading }
                          error={ allError }
                          inquiries={ inquiries }
                      />
                    </Tab>
                );
              })
            }
          </Tabs>
        </Container>

      </PageWithNavigation>
  );
};