import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '../../components/Layout/Container/Container';
import { Title } from '../../components/Layout/Title/Title';
import { Routes } from '../../constants/Routes';
import { PageWithNavigation } from '../PageWithNavigation';
import './HomePage.scss';
import { Team } from './Team';

export const HomePage: React.FC = () => {
  return (
      <PageWithNavigation>
        <Helmet title="Schwimmkurse für Kinder in Winsen">
          <meta name="description" content="Vom ersten Kontakt mit dem Wasser bis zum Gold Abzeichen. Finde deinen perfekten Schwimmkurs in Winsen Luhe, im Freizeitbad-die-Insel." />
        </Helmet>
        
        <Container className="welcome">
          <div className="welcome-box">
            <Title
                className="welcome-box"
                title={
                  <picture className="welcome-logo">
                    <source type="image/avif" srcSet="/img/logo.avif"/>
                    <img src="/img/logo.png" alt="Schwimmschule Schulz" width={ 280 } height={ 288.29 }/>
                  </picture>
                }
                subtitle={ <>
                  <span className="is-block">Schwimmen lernen mit Herz und Flossen</span>
                  <span className="is-block">von der Wassergewöhnung bis zum Goldabzeichen</span>
                </> }/>
            <p className="block">
              Mit großer Liebe zum Wasser und dem Antrieb, dies an dein Kind weiterzugeben, gestalten wir unsere Kurse.
              Wir wollen deinem Kind Spaß am nassen Element vermitteln, ihm dabei helfen noch bestehende Ängste
              abzubauen, mit ihm Tauchen, Gleiten und Blubbern und vor Allem die korrekten Schwimmtechniken schulen.
            </p>
            <p className="block">
              Denn, wenn man einmal richtig Schwimmen lernt, hat man sein Leben lang Freude im und am Lebensraum Wasser.
            </p>
          </div>

          <div className="offers-box box">
            <h2 className="title is-3">Unsere Kurse</h2>
            <p className="block">
              In unseren Schwimmstunden legen wir besonders viel Wert darauf, dass sich die Kinder wohl und sicher
              fühlen, gefordert aber nicht überfordert werden und Wasser mit einer Menge Spaß verbinden.
            </p>
            <p className="block">
              <span className="float-right is-relative m-2" style={ { width: 100, height: 150 } }>
                <img style={ { position: 'absolute', right: '1rem', bottom: 0 } } src="/img/algen/Alge1.svg" alt=""/>
                <img style={ { position: 'absolute', right: 0, bottom: -10, width: 60 } } src="/img/algen/Alge9.svg"
                     alt=""/>
              </span>
              Durch zahlreiche Übungen, Spiele und auch Pyjamapartys (in Bronze-Silber- und Goldkursen) erlernen die
              Kinder mit Freude und Begeisterung die Schwimmbewegungen, Tauchen und Springen wie von selbst.
            </p>
            <a href={ Routes.Offers.path } className="button is-primary is-fullwidth">Hier findest du deinen
              Schwimmkurs</a>
          </div>
        </Container>

        <Team/>

      </PageWithNavigation>
  );
};