import React from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from 'react-oidc-context';
import { PageWithNavigation } from '../../pages/PageWithNavigation';
import { Button } from '../Form/Button/Button';
import { Container } from '../Layout/Container/Container';
import { Title } from '../Layout/Title/Title';
import './PrivateRoute.scss';

interface PrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }: PrivateRouteProps) => {
  const auth = useAuth();

  const login = () => {
    auth.signinRedirect({
      redirect_uri: window.location.href,
    });
  };

  if (auth.isLoading) {
    return <h3>Loading...</h3>;
  }

  if (!auth.isAuthenticated) {
    return <PageWithNavigation>
      <Helmet title="Bitte anmelden">

      </Helmet>

      <Container>
        <Title title="Bitte anmelden" subtitle="Diese Seite ist nicht öffentlich. Bitte melde dich an."/>

        <div className="unauthorized-content">
          <div className="call-to-action">
            <p className="block">
              Wenn du einen Account hast, nutze bitte den folgenden Link um zur Anmeldung zu gelangen. Anschließend
              solltest du die von Dir angefragte Seite sehen können
            </p>

            <Button color="primary" onClick={ login }>
              Zur Anmeldung
            </Button>
            <Button color="text" href="/">
              Zur Startseite
            </Button>
          </div>

          <img src="/img/undraw_access_account_re_8spm.svg" alt="" />
        </div>
      </Container>
    </PageWithNavigation>;
  }
  return <>{ children }</>;
};