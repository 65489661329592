import classNames from 'classnames';
import React from 'react';
import { v4 as uuid } from 'uuid';
import './Checkbox.scss';


interface CheckboxProps {
  id?: string;
  checked: boolean;
  disabled?: boolean;
  onChange?: (next: boolean) => void;
  error?: string;
  children?: React.ReactNode;
}

export const Checkbox: React.FC<CheckboxProps> = ({
                                                    id,
                                                    checked,
                                                    disabled,
                                                    onChange,
                                                    error,
                                                    children,
                                                  }: CheckboxProps) => {
  const inputId = id ?? uuid();

  let errorText;
  if (!!error) {
    errorText = <p className="help is-danger">{ error }</p>;
  }

  return (
      <div className="Checkbox field">
        <div className={ classNames('control') }>
          <label htmlFor={ inputId }>
            <input
                id={ inputId }
                className="mr-2"
                type="checkbox"
                checked={ checked }
                disabled={ disabled }
                onChange={ () => onChange?.(!checked) }
            />
            { children }
          </label>
        </div>
        { errorText }
      </div>
  );
};