import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import './Row.scss';

interface RowProps {
  className?: string;
  expandableContent?: ReactNode;
  children: ReactNode;
}

export const Row: React.FC<RowProps> = ({ className, expandableContent, children }: RowProps) => {
  const [expanded, setExpanded] = useState(false);

  let expandableCell = null;
  if (!!expandableContent) {
    expandableCell = (
        <td>
          <span className="icon expand-icon">
            <FontAwesomeIcon icon={ faChevronDown } />
          </span>
        </td>
    )
  }
  
  return (<>
        <tr
            className={ classNames(className, 'row', { expandable: !!expandableContent, expanded: expanded }) }
            onClick={() => setExpanded(!expanded)}
        >
          { expandableCell }
          { children }
        </tr>
        { expandableContent && expanded && (
            <tr>
              <td></td>
              { expandableContent }
            </tr>
        ) }
      </>
  );
};