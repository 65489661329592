import { useEffect, useState } from 'react';

export enum Screen {
  MOBILE = 0,
  TABLET = 750,
  DESKTOP = 1050,
}

export interface Width {
  screen: Screen;
  width: number;
  isMobile: boolean;
}

export const useWidth = (): Width => {
  const [width, setWidth] = useState<Width>({ screen: Screen.MOBILE, width: 0, isMobile: true });

  const handleResize = () => {
    const w = window.innerWidth;
    let s: Screen;
    if (w >= Screen.DESKTOP) {
      s = Screen.DESKTOP;
    } else if (w >= Screen.TABLET) {
      s = Screen.TABLET;
    } else {
      s = Screen.MOBILE;
    }

    setWidth({
      width: w,
      screen: s,
      isMobile: s === Screen.MOBILE,
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return width;
};