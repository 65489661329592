import React from 'react';
import { Helmet } from 'react-helmet';
import { RequestForm } from '../../../components/Form/RequestForm/RequestForm';
import { Card } from '../../../components/Layout/Card/Card';
import { Container } from '../../../components/Layout/Container/Container';
import { UnsortedList } from '../../../components/Layout/List/UnsortedList';
import { Section } from '../../../components/Layout/Section/Section';
import { Title } from '../../../components/Layout/Title/Title';
import { PageWithNavigation } from '../../PageWithNavigation';
import '../OfferPage/OfferPage.scss';

export const Gold: React.FC = () => {

  const goldChecks = [
    'es das Silberabzeichen erworben hat',
    'es regelmäßig nach dem Silberabzeichen im Wasser war',
    'es mind. 16 Bahnen (400m) am Stück sicher schwimmt',
    'es mind. 9 Jahre ist, Spaß im Wasser und Lust hat, die Anfänge des Kraulschwimmens zu erlernen',
    'es schnell schwimmt und mind. 10m weit tauchen kann',
    'es sich gut konzentrieren kann, sich gut in eine Gruppe einfügt und motorisch gut drauf ist',
  ];

  return (
      <PageWithNavigation>
        <Helmet title="Goldkurse für Kinder in Winsen">
          <meta name="description" content="Mache dein Gold Abzeichen in Winsen Luhe, im Freizeitbad-die-Insel. Schwimmkurs für Kinder ab 9 Jahren." />
        </Helmet>

        <Container>
          <div className="information-container">
            <Title className="title-box" title="Gold" subtitle="Für Kinder ab 9 Jahren"/>

            <div className="overview-box">
              <h2 className="title is-4">Der Kurs im Überblick</h2>
              <UnsortedList>
                <li>Ziel des Kurses: Erlernen der Grobform des Kraulschwimmens, alle Anforderungen an das Goldabzeichen
                  zu meistern
                </li>
                <li>1 x in der Woche à 1 Stunde</li>
                <li>10 Termine (Kurstermine Mi und Do ab 18:00 Uhr)</li>
                <li>Eltern nicht dabei, außer ein <a href="/faq#eltern">Begleitelternteil</a> pro Gruppe</li>
                <li>Gruppengröße: 8 Kinder</li>
                <li>Preis: 185€ pro Kind (inklusive Eintritt, abgetrennten Schwimmbereich und Abzeichen)</li>
              </UnsortedList>
            </div>

            <div className="logo-box">
              <picture>
                <source type="image/avif" srcSet="/img/abzeichen/gold_400x400.avif" />
                <img src="/img/abzeichen/gold_400x400.png" alt="" width={ 400 } height={ 400 } />
              </picture>
            </div>
          </div>
        </Container>

        <Container>
          <Card>
            <RequestForm
                course="Gold"
                checklist={ {
                  title: 'Bitte kreuze alles an, damit wir sichergehen können, dass deine Kind bereit für den Goldkurs ist.',
                  checks: goldChecks,
                } }
            />
          </Card>
        </Container>

        <Container className="course-footer">
          <Section title="Gold Abzeichen">
            <div className="is-flex-1">
              <p>Um dieses Abzeichen zu erhalten müssen folgende Punkte erfüllt werden:</p>
              <UnsortedList>
                <li>Sprung kopfwärts vom Beckenrand und 30 Minuten Schwimmen. In dieser Zeit sind mind. 800m
                  zurückzulegen, davon 650m in Bauch- oder Rückenlage in einer erkennbaren Schwimmart und 150m in der
                  anderen Körperlage.
                </li>
                <li>Startsprung und 25m Kraulschwimmen.</li>
                <li>Startsprung und 50m Brustschwimmen in höchstens 1:15 Minuten.</li>
                <li>50m Rückenschwimmen.</li>
                <li>10m Streckentauchen aus der Schwimmlage, ohne Abstoß.</li>
                <li>Dreimal ca. 2m Tieftauchen von der Wasseroberfläche mit Heraufholen eines kleinen
                  Gegenstandes.
                </li>
                <li>Ein Sprung aus 3m Höhe oder 2 verschiedene Sprünge aus 1m Höhe.</li>
                <li>50m Transportschwimmen: Schieben und Ziehen.</li>
                <li>Kenntnis der Baderegeln, Selbstrettung und einfache Fremdrettung.</li>
              </UnsortedList>
            </div>
            <picture>
              <source type="image/avif" srcSet="/img/abzeichen/gold_400x400.avif" />
              <img src="/img/abzeichen/gold_400x400.png" alt="" width={ 200 } height={ 200 } />
            </picture>
          </Section>

          <Section>
            <p>Es gibt keine Garantie für das jeweilige Abzeichen, da jedes Kind mit seiner
              Entwicklung
              einzigartig ist. Wir holen dein Kind immer da ab, wo es steht und geben unser Bestes, es mit einer
              sicheren
              Schwimmtechnik zum jeweiligen Abzeichen zu führen.</p>
          </Section>
        </Container>
      </PageWithNavigation>
  );
};