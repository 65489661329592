import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from '../../../components/Layout/Card/Card';
import './OfferBox.scss';

interface OfferBoxProps {
  title: string;
  subtitle: string;
  offerPath: string;
  imgSrc: string;
  avifSrc: string;
}

export const OfferBox: React.FC<OfferBoxProps> = ({ title, subtitle, offerPath, imgSrc, avifSrc }: OfferBoxProps) => {
  const navigate = useNavigate();

  const openOffer = () => {
    navigate(offerPath);
  };

  return (
      <Card 
          onClick={ openOffer } 
          className="offer"
          imgSrc={imgSrc}
          avifSrc={avifSrc}
          title={title}
          subtitle={subtitle}
      >
        <div className="is-flex-1 more-link">
          <Link to={ offerPath }>Hier anmelden</Link>
        </div>
      </Card>
  );
};