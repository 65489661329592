import { catchError, EMPTY, finalize, take, tap } from 'rxjs';
import { create } from 'zustand';
import { AgbStatus } from '../data/agb.interface';
import { Inquiry } from '../data/inquiry.interface';
import { HTTP_POST } from '../http/http';

interface CallbackActions<T> {
  onSuccess?: (d: T) => void;
  onError?: (errorMessage: string) => void;
}

interface InquiriesStore {
  acceptAgbStatus?: AgbStatus;
  acceptAgbPending: boolean;
  acceptAgb: (POST: HTTP_POST, inquiryId: string) => void;
  error?: string;
}

export const useAgbs = create<InquiriesStore>((set, getState) => ({
  acceptAgbStatus: undefined,
  acceptAgbPending: false,
  error: undefined,
  acceptAgb: (POST: HTTP_POST, inquiryId: string) => {
    set({ acceptAgbPending: true });
    POST<null, AgbStatus>('/api/inquiries/' + inquiryId + '/agb/accept', null).pipe(
        take(1),
        tap(acceptAgbStatus => {
          set({
            error: undefined,
            acceptAgbStatus,
          });
        }),
        catchError(err => {
          console.error(err);
          set({ error: 'Leider gab es Probleme beim Akzeptieren der AGB. Wende dich direkt an uns.' });
          return EMPTY;
        }),
        finalize(() => set({ acceptAgbPending: false })),
    ).subscribe();
  },
}));
