import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import './DateOfBirthInput.scss';

interface DateOfBirthInputProps {
  id?: string;
  label?: string;
  required?: boolean;
  value: Moment;
  onChange: (next: Moment) => void;
  prefixIcon?: React.ReactNode;
  error?: string;
}

export const DateOfBirthInput: React.FC<DateOfBirthInputProps> = ({
                                                                    id,
                                                                    label,
                                                                    required,
                                                                    value,
                                                                    onChange,
                                                                    prefixIcon,
                                                                    error,
                                                                  }: DateOfBirthInputProps) => {
  const inputId = id ?? uuid();
  const [day, setDay] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');

  useEffect(() => {
    if (value.isValid()) {
      setDay(value.format('DD'));
      setMonth(value.format('MM'));
      setYear(value.format('YYYY'));
    }
  }, [value]);

  useEffect(() => {
    if (day?.length === 2 && month?.length === 2 && year?.length === 4) {
      const next = moment(`${ year }-${ month }-${ day }`, 'YYYY-MM-DD');
      if (next.isValid()) {
        onChange(next);
      } else {
        onChange(moment.invalid());
      }
    } else {
      onChange(moment.invalid());
    }
  }, [day, month, year]);

  let errorIcon;
  let errorText;
  if (!!error) {
    errorIcon = (
        <span className="icon is-small is-right">
          <FontAwesomeIcon icon={ faExclamationTriangle }/>
        </span>
    );
    errorText = <p className="help is-danger">{ error }</p>;
  }

  const updateDay = (next: string) => {
    if (next.length === 2) {
      const m: HTMLInputElement | null = document.getElementById(inputId + '_month') as HTMLInputElement;
      m?.select();
    }
    setDay(next);
  };
  
  const fillDay = () => {
    if (day.length === 1) {
      setDay("0" + day);
    }
  }

  const updateMonth = (next: string) => {
    if (next.length === 2) {
      const m: HTMLInputElement | null = document.getElementById(inputId + '_year') as HTMLInputElement;
      m?.select();
    }
    setMonth(next);
  };

  const fillMonth = () => {
    if (month.length === 1) {
      setMonth("0" + month);
    }
  }
  
  const updateYear = (next: string) => {
    if (next.length === 4) {
      
    }
    setYear(next);
  }

  const fillYear = () => {
    if (year.length === 1) {
      setYear("000" + year);
    } else if (year.length === 2) {
      setYear("00" + year);
    } else if (year.length === 3) {
      setYear("0" + year);
    }
  }

  return (
      <div className="date-of-birth-input filed">
        <label className="label" id={ inputId + '_label' }>
          { label }
          { required && <span className="has-text-danger">*</span> }
        </label>
        <div className="controls">
          <div className={ classNames('control day', { 'has-icons-left': !!prefixIcon }) }>
            <input
                id={ inputId + '_day' }
                className={ classNames('input', { 'is-danger': !!error }) }
                type="text"
                inputMode="numeric"
                maxLength={ 2 }
                value={ day }
                placeholder="DD"
                onChange={ e => updateDay(e.target.value) }
                onBlur={fillDay}
            />

            { prefixIcon }
          </div>
          <div className="control month">
            <input
                id={ inputId + '_month' }
                className={ classNames('input', { 'is-danger': !!error }) }
                type="text"
                inputMode="numeric"
                maxLength={ 2 }
                value={ month }
                placeholder="MM"
                onChange={ e => updateMonth(e.target.value) }
                onBlur={fillMonth}
            />
          </div>
          <div className={ classNames('control year', { 'has-icons-right': !!errorIcon }) }>
            <input
                id={ inputId + '_year' }
                className={ classNames('input', { 'is-danger': !!error }) }
                type="text"
                inputMode="numeric"
                maxLength={ 4 }
                value={ year }
                placeholder="JJJJ"
                onChange={ e => updateYear(e.target.value) }
                onBlur={fillYear}
            />

            { errorIcon }
          </div>
        </div>

        { errorText }
      </div>
  );
};