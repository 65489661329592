import { faBirthdayCake, faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Routes } from '../../../constants/Routes';
import { Course, InquiryRequest, isAdult } from '../../../data/inquiry.interface';
import { useHttp } from '../../../http/http';
import { Validation } from '../../../utils/validation';
import { useInquiries } from '../../../zustand/inquiries.store';
import { Button } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { DateOfBirthInput } from '../DateOfBirthInput/DateOfBirthInput';
import { Input } from '../Input/Input';
import './RequestForm.scss';

interface Checklist {
  title?: string;
  subtitle?: string;
  checks?: string[];
}

interface RequestFormProps {
  course: Course;
  checklist?: Checklist;
  courseOptions?: Checklist
  manual?: boolean;
}

const NEW_REQUST = (course: Course, checks: string[], favoriteCourseOptions: string[]): InquiryRequest => ({
  course: course,
  firstname: '',
  lastname: '',
  dateOfBirth: '',
  email: '',
  phone: '',
  child: {
    firstname: '',
    lastname: '',
    dateOfBirth: '',
  },
  notes: '',
  checks: checks.map(it => ({ text: it, checked: false })),
  favoriteCourseOptions: favoriteCourseOptions.map(it => ({ text: it, checked: false })),
  allowSaveData: false,
  allowContact: false,
  acceptedAgb: false,
});

type Errors = { [key: string]: string };
type Warnings = { [key: string]: string };

export const RequestForm: React.FC<RequestFormProps> = ({ course, checklist, courseOptions, manual = false }: RequestFormProps) => {
  const { POST } = useHttp();
  const navigate = useNavigate();
  const save = useInquiries(state => state.save);
  const saveLoading = useInquiries(state => state.saveLoading);
  const saveError = useInquiries(state => state.saveError);

  // TODO move requestData to state
  const [request, setRequest] = useState<InquiryRequest>(
      NEW_REQUST(course, checklist?.checks ?? [], courseOptions?.checks ?? [])
  );
  const [errors, setErrors] = useState<Errors>({});
  const [warnings, setWarnings] = useState<Warnings>({});

  useEffect(() => {
    updateCourse(course);
  }, [course]);

  const updateCourse = (next: Course) => setRequest(r => ({ ...r, course: next }));
  const updateFirstname = (next: string) => setRequest(r => ({ ...r, firstname: next }));
  const updateLastname = (next: string) => setRequest(r => ({ ...r, lastname: next }));
  const updateDateOfBirth = (next: Moment) => setRequest(r => ({
    ...r,
    dateOfBirth: next.isValid() ? next.format('YYYY-MM-DD') : '',
  }));
  const updateEmail = (next: string) => setRequest(r => ({ ...r, email: next }));
  const updatePhone = (next: string) => setRequest(r => ({ ...r, phone: next }));
  const updateChildFirstname = (next: string) => setRequest(r => ({ ...r, child: { ...r.child, firstname: next } }));
  const updateChildLastname = (next: string) => setRequest(r => ({ ...r, child: { ...r.child, lastname: next } }));
  const updateChildDateOfBirth = (next: Moment) => setRequest(r => ({
    ...r,
    child: { ...r.child, dateOfBirth: next.isValid() ? next.format('YYYY-MM-DD') : '' },
  }));
  const updateNotes = (next: string) => setRequest(r => ({ ...r, notes: next }));

  const updateAllowSaveData = (next: boolean) => setRequest(r => ({ ...r, allowSaveData: next }));
  const updateAllowContact = (next: boolean) => setRequest(r => ({ ...r, allowContact: next }));
  const updateAcceptAgb = (next: boolean) => setRequest(r => ({ ...r, acceptedAgb: next }));

  const updateCheck = (idx: number, next: boolean) => setRequest(r => {
    const updated: InquiryRequest = { ...r };
    if (updated.checks) {
      const checkToUpdate = updated.checks[idx];
      checkToUpdate.checked = next;
    }
    return updated;
  });
  
  const updateFavoriteCourseOption = (idx: number, next: boolean) => setRequest(r => {
    const updated: InquiryRequest = { ...r };
    if (updated.favoriteCourseOptions) {
      const checkToUpdate = updated.favoriteCourseOptions[idx];
      checkToUpdate.checked = next;
    }
    return updated;
  });

  const validateAndSend = () => {
    const valid = validate();
    if (valid) {
      save(POST, request, {
        onSuccess: () => {
          clearForm();
          navigate(Routes.OfferAccepted.path);
        },
      });
    }
  };

  const clearForm = () => {
    console.info('clear form');
    setRequest(NEW_REQUST(course, checklist?.checks ?? [], courseOptions?.checks ?? []));
  };

  const validate = (): boolean => {
    const e: Errors = {};
    const w: Warnings = {};
    const adultCourse = course == 'Erwachsene';
    let focused = false;
    // validate child information
    if (!adultCourse && Validation.isNullOrEmpty(request.child.firstname)) {
      e.child_firstname = 'Gib bitte den Vornames des Kindes an';
      focused = focusIfNotYetFocusedAnyField(focused, 'child_firstname');
    }
    if (!adultCourse && Validation.isNullOrEmpty(request.child.lastname)) {
      e.child_lastname = 'Gib bitte den Nachnamen des Kindes an';
      focused = focusIfNotYetFocusedAnyField(focused, 'child_lastname');
    }
    if (!adultCourse && Validation.isNullOrEmpty(request.child.dateOfBirth)) {
      e.child_date_of_birth = 'Gib bitte das Geburtsdatum des Kindes an';
      focused = focusIfNotYetFocusedAnyField(focused, 'child_date_of_birth');
    }

    // validate contact person, e.g. parent
    if (Validation.isNullOrEmpty(request.firstname)) {
      e.firstname = 'Gib bitte deinen Vornamen an';
      focused = focusIfNotYetFocusedAnyField(focused, 'firstname');
    }
    if (Validation.isNullOrEmpty(request.lastname)) {
      e.lastname = 'Gib bitte deinen Nachnamen an';
      focused = focusIfNotYetFocusedAnyField(focused, 'firstname');
    }
    if (adultCourse && Validation.isNullOrEmpty(request.dateOfBirth)) {
      e.date_of_birth = 'Gib bitte dein Geburtsdatum an';
      focused = focusIfNotYetFocusedAnyField(focused, 'date_of_birth');
    }
    if (!manual && Validation.isNullOrEmpty(request.email)) {
      e.email = 'Gib bitte deine E-Mail-Adresse an';
      focused = focusIfNotYetFocusedAnyField(focused, 'email');
    }
    if (Validation.isNullOrEmpty(request.phone)) {
      e.phone = 'Gib bitte deine Handynummer an';
      focused = focusIfNotYetFocusedAnyField(focused, 'phone');
    } else if (
        (!request.phone.startsWith('+491') && !request.phone.startsWith('01'))
    ) {
      w.phone = 'Bitte überprüfe deine Handynummer';
      focused = focusIfNotYetFocusedAnyField(focused, 'phone');
    }

    if (!manual && !request.allowSaveData) {
      e.allow_save_data = 'Bitte bestätige, dass wir deine Daten verarbeiten und speichern dürfen';
      focused = focusIfNotYetFocusedAnyField(focused, 'allow_save_data');
    }
    if (!manual && !request.allowContact) {
      e.allow_contact = 'Bitte bestätige, dass wir dich kontaktieren dürfen';
      focused = focusIfNotYetFocusedAnyField(focused, 'allow_contact');
    }
    if (!manual && !request.acceptedAgb) {
      e.accept_agb = 'Bitte bestätige, dass du die AGB gelesen hast und diese akzeptierst';
      focused = focusIfNotYetFocusedAnyField(focused, 'accept_agb');
    }
    setErrors(e);
    setWarnings(w);
    return Object.keys(e).length === 0;
  };

  const focusIfNotYetFocusedAnyField = (alreadyAnyFieldFocused: boolean, id: string): boolean => {
    if (alreadyAnyFieldFocused) {
      return true;
    }
    const element = document.getElementById(id) as HTMLInputElement;
    if (!element) {
      return false;
    }
    element.select();
    element.scrollIntoView();
    return true;
  };

  return (
      <div className="request-form">
        <div className="data">
          {
              course !== 'Erwachsene' && (
                  <div className="child">
                    <h2 className="title is-3">Daten des Kindes</h2>
                    <p className="subtitle is-5">Gib hier bitte die Daten des Kindes an, das am Schwimmkurs teilnehmen
                      soll.</p>
                    <Input
                        id="child_firstname"
                        label="Vorname des Kindes"
                        required
                        placeholder="Gib hier den Vornamen des Kindes an"
                        value={ request.child.firstname }
                        onChange={ updateChildFirstname }
                        prefixIcon={
                          <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={ faUser }/>
                    </span>
                        }
                        error={ errors.child_firstname }
                    />
                    <Input
                        id="child_lastname"
                        label="Nachname des Kindes"
                        required
                        placeholder="Gib hier den Nachnamen des Kindes an"
                        value={ request.child.lastname }
                        onChange={ updateChildLastname }
                        prefixIcon={
                          <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={ faUser }/>
                    </span>
                        }
                        error={ errors.child_lastname }
                    />
                    <DateOfBirthInput
                        id="child_date_of_bith"
                        label="Geburtsdatum des Kindes"
                        required
                        value={ moment(request.child.dateOfBirth) }
                        onChange={ updateChildDateOfBirth }
                        prefixIcon={
                          <span className="icon is-small is-left">
                            <FontAwesomeIcon icon={ faBirthdayCake }/>
                          </span>
                        }
                        error={ errors.child_date_of_birth }
                    />
                  </div>
              )
          }
          <div className="contact">
            <h2 className="title is-3">{
              manual ? 'Kontaktdaten' : 'Deine Kontaktdaten'
            }</h2>
            <p className="subtitle is-5">{ manual
                ? 'Wen können wir bei Rückfragen anrufen oder anschreiben?'
                : 'Wer bist du und wie können wir dich erreichen, um dir deinen Kurs mitzuteilen?'
            }</p>
            <Input
                id="firstname"
                label="Vorname"
                required
                placeholder="Gib deinen Vornamen an"
                value={ request.firstname }
                onChange={ updateFirstname }
                prefixIcon={
                  <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={ faUser }/>
                    </span>
                }
                error={ errors.firstname }
            />
            <Input
                id="lastname"
                label="Nachname"
                required
                placeholder="Gib deinen Nachnamen an"
                value={ request.lastname }
                onChange={ updateLastname }
                prefixIcon={
                  <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={ faUser }/>
                    </span>
                }
                error={ errors.lastname }
            />
            { isAdult(course) && (
                <DateOfBirthInput
                    id="date_of_bith"
                    label="Geburtsdatum"
                    required
                    value={ moment(request.dateOfBirth) }
                    onChange={ updateDateOfBirth }
                    prefixIcon={
                      <span className="icon is-small is-left">
                            <FontAwesomeIcon icon={ faBirthdayCake }/>
                          </span>
                    }
                    error={ errors.date_of_birth }
                />
            ) }
            <Input
                id="email"
                label="E-Mail-Adresse"
                required={ !manual }
                placeholder="Gib deine E-Mail-Adresse an"
                value={ request.email }
                onChange={ updateEmail }
                type="email"
                prefixIcon={
                  <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={ faEnvelope }/>
                    </span>
                }
                error={ errors.email }
            />
            <Input
                id="phone"
                label="Handynummer"
                required
                placeholder="Gib eine Handynummer an"
                value={ request.phone }
                onChange={ updatePhone }
                type="tel"
                prefixIcon={
                  <span className="icon is-small is-left">
                      <FontAwesomeIcon icon={ faPhone }/>
                    </span>
                }
                error={ errors.phone }
                warning={ warnings.phone }
            />
          </div>

        </div>

        <div>
          mit <span className="has-text-danger">*</span> markierte Felder sind Pflichtfelder
        </div>

        <div className="wishes">
          <Input
              id="wishes"
              label="Wünsche und Anmerkungen"
              placeholder="Gib hier sonstige Wünsche oder Anmerkungen an"
              value={ request.notes }
              onChange={ updateNotes }
              type="textarea"
              rows={ 3 }
          />
        </div>

        {
           !! checklist?.checks?.length && (
                <div className="block">
                  <h2 className="title is-3">Checkliste</h2>
                  <p className="subtitle is-5">{ checklist?.title }</p>
                  <p className="block">{ checklist?.subtitle ?? 'Mein Kind ist bereit für diesen Kurs, weil...' }</p>
                  <ul>
                    { checklist?.checks?.map((text, i) => (
                        <li key={ text }>
                          <Checkbox checked={ !!request.checks?.[i].checked } onChange={ next => updateCheck(i, next) }>
                            { text }
                          </Checkbox>
                        </li>
                    )) }
                  </ul>
                </div>
            )
        }


        {
            !!courseOptions?.checks?.length && (
                <div className="block">
                  <h2 className="title is-3">{ courseOptions?.title }</h2>
                  <p className="subtitle is-5">{ courseOptions?.subtitle }</p>
                  <ul>
                    { courseOptions?.checks?.map((text, i) => (
                        <li key={ text }>
                          <Checkbox checked={ !!request.favoriteCourseOptions?.[i].checked } onChange={ next => updateFavoriteCourseOption(i, next) }>
                            { text }
                          </Checkbox>
                        </li>
                    )) }
                  </ul>
                </div>
            )
        }

        { !manual && (
            <div className="block">
              <p className="block">
                Vielen Dank für deine Angaben.
              </p>
            </div>
        ) }


        <div className="block">
          { !manual && (
              <>
                <Checkbox
                    id="accept_agb"
                    checked={ request.acceptedAgb }
                    onChange={ updateAcceptAgb }
                    error={ errors.accept_agb }
                >
                  Ich akzeptiere die <Link to={Routes.Agb.path}>{Routes.Agb.title}</Link>.
                </Checkbox>
                <Checkbox
                    id="allow_save_data"
                    checked={ request.allowSaveData }
                    onChange={ updateAllowSaveData }
                    error={ errors.allow_save_data }
                >
                  Ich stimme der Übermittlung und Verarbeitung meiner Daten zu und akzeptiere die <Link to={Routes.Datenschutz.path}>{Routes.Datenschutz.title}</Link>. 
                </Checkbox>
                <Checkbox
                    id="allow_contact"
                    checked={ request.allowContact }
                    onChange={ updateAllowContact }
                    error={ errors.allow_contact }
                >
                  Ich bin damit einverstanden per E-Mail, Telefon und WhatsApp kontaktiert zu werden.
                </Checkbox>
              </>
          ) }
        </div>
        
        <div className="block">
          <Button color="primary" onClick={ validateAndSend } disabled={ saveLoading }>
            {
              saveLoading
                  ? 'Anfrage wird verschickt...'
                  : 'Auf die Warteliste'
            }
          </Button>
        </div>
      </div>
  )
      ;
};