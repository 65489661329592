import React from 'react';
import { Helmet } from 'react-helmet';
import { RequestForm } from '../../../components/Form/RequestForm/RequestForm';
import { Card } from '../../../components/Layout/Card/Card';
import { Container } from '../../../components/Layout/Container/Container';
import { UnsortedList } from '../../../components/Layout/List/UnsortedList';
import { Section } from '../../../components/Layout/Section/Section';
import { Title } from '../../../components/Layout/Title/Title';
import { PageWithNavigation } from '../../PageWithNavigation';


export const Seepferdchen: React.FC = () => {

  const seepferdchenChecks = [
    'es regelmäßig im Wasser ist, Wasser im Gesicht und über dem Kopf kein Problem ist',
    'es mind. 5 Jahre alt ist und keine Angst vor Wasser hat',
    'es tauchen kann (idealerweise schon unter Wasser blubbert und die Augen aufmacht)',
    'es schon einmal (mit Hilfsmitteln) im großen Becken der Insel war',
    'es Spaß im Wasser hat und Schwimmen lernen will',
    'es sich gut konzentrieren kann, sich gut in eine Gruppe einfügt (auch ohne Eltern), ohne Hilfe auf Toilette geht und motorisch gut drauf ist',
  ];
  
  const seepferdchenCourseOptions = [
    'Kurs I : Dienstag (15:30-17:00) und Freitag (14:30-16:00) - 10 Kurstermine',
    'Kurs II: Dienstag (16:15-17:30) und Mittwoch (16:45-18:00) - 12 Kurstermine',
    'Kurs III: Dienstag (17:00-18:15) und Mittwoch (17:30-18:45) - 12 Kurstermine',
    'Kurs IV: Mittwoch (15:15-16:45) und Donnerstag (14:45-16:15) - 10 Kurstermine',
    'Kurs V: Mittwoch (16:15-17:45) und Freitag (16:15-17:45) - 10 Kurstermine',
    'ich bin flexibel'
  ];

  return (
      <PageWithNavigation>
        <Helmet title="Seepferdchenkurse für Kinder in Winsen">
          <meta name="description" content="Mache dein Seepferchen in Winsen Luhe, im Freizeitbad-die-Insel. Schwimmkurs für Kinder ab 5 Jahren." />
          <meta property="og:title" content="Seepferchenkurse für Kinder in Winsen" />
          <meta property="og:description" content="Mache dein Seepferchen in Winsen" />
        </Helmet>

        <Container>
          <div className="information-container">
            <Title className="title-box" title="Seepferdchen" subtitle="Für Kinder ab 5 Jahren"/>

            <div className="overview-box">
              <h2 className="title is-4">Der Kurs im Überblick</h2>
              <UnsortedList>
                <li>Voraussetzungen: Ein Wassergewöhnungskurs und/oder Erfüllen der Checkliste</li>
                <li>Ziel des Kurses: Erlernen der korrekten Bein- und Armtechnik fürs Brustschwimmen</li>
                <li>Mit Freude am Wasser die Anforderung ans Seepferdchen meistern, bzw. Schwimmen im
                  großen Becken erlernen. Wenn eine Strecke von weniger als 25m zurückgelegt wird, erhält
                  das Kind die Robbe plus Urkunde
                </li>
                <li>Kein Kind verlässt den Kurs i.d.Regel ohne eines dieser Abzeichen</li>
                <li>2 x in der Woche à 1 Stunde</li>
                <li>10 Termine (Kurstermine Di, Mi, Fr ab 14:30 Uhr)</li>
                <li>Eltern nicht dabei, außer ein <a href="/faq#eltern">Begleitelternteil</a> pro Gruppe</li>
                <li>Gruppengröße: 6 Kinder</li>
                <li>Lehrerin immer mit im Wasser</li>
                <li>Keine wechselnden Schwimmlehrer</li>
                <li>Preis: 250€ pro Kind (inklusive Eintritt, abgetrennten Schwimmbereich und Abzeichen)</li>
              </UnsortedList>
            </div>

            <div className="logo-box">
              <picture>
                <source type="image/avif" srcSet="/img/abzeichen/seepferdchen_400x400.avif" />
                <img src="/img/abzeichen/seepferdchen_400x400.png" alt="" width={ 400 } height={ 400 } />
              </picture>
            </div>
          </div>
        </Container>

        <Container>
          <Card>
            <RequestForm 
                course="Seepferdchen"
                checklist={ {
                  title: 'Bitte kreuze alles an, damit wir sichergehen können, dass dein Kind bereit für den Seepferdchenkurs ist.',
                  checks: seepferdchenChecks,
                } }
                courseOptions={{
                  title: 'Aktuelle Kurse',
                  subtitle: 'Bitte sag uns schon jetzt, welche Zeiten dir am besten passen würden (Mehrfachauswahl möglich)',
                  checks: seepferdchenCourseOptions,
                }}
            />
          </Card>
        </Container>

        <Container className="course-footer">
          <Section title="Seepferdchen Abzeichen">
            <div className="is-flex-1">
              <p>Um dieses Abzeichen zu erhalten müssen folgende Punkte erfüllt werden:</p>
              <UnsortedList>
                <li>Kenntnis von Baderegeln</li>
                <li>Sprung vom Beckenrand mit anschließendem 25m Schwimmen in einer Schwimmart in
                  Bauch- oder Rückenlage (Grobform, während des Schwimmens in Bauchlage erkennbar ins Wasser ausatmen).
                </li>
                <li>Heraufholen eines Gegenstandes mit den Händen aus schultertiefem Wasser.</li>
              </UnsortedList>
            </div>
            <picture>
              <source type="image/avif" srcSet="/img/abzeichen/seepferdchen_400x400.avif" />
              <img src="/img/abzeichen/seepferdchen_400x400.png" alt="" width={ 200 } height={ 200 } />
            </picture>
          </Section>

          <Section title="Schwimmurkunde Bobby mit Abzeichen">
            <div className="is-flex-1">
              <p>Um dieses Abzeichen zu erhalten müssen folgende Punkte erfüllt werden:</p>
              <UnsortedList>
                <li>1-24m Schwimmen ohne Hilfsmittel</li>
              </UnsortedList>
            </div>
            <img src="/img/schwimmurkunde-booby.jpeg" alt="" width={200}/>
          </Section>

          <Section>
            <p>Es gibt keine Garantie für das jeweilige Abzeichen, da jedes Kind mit seiner
              Entwicklung
              einzigartig ist. Wir holen dein Kind immer da ab, wo es steht und geben unser Bestes, es mit einer
              sicheren
              Schwimmtechnik zum jeweiligen Abzeichen zu führen.</p>
          </Section>
        </Container>
      </PageWithNavigation>
  );
};
