import React from 'react';
import { Link } from 'react-router-dom';

interface NavItemProps {
  to: string;
  children: React.ReactNode;
}

export const NavItem: React.FC<NavItemProps> = ({ to, children }: NavItemProps) => (
    <Link to={ to } role="menuitem" className="navbar-item">
      { children }
    </Link>
);