import React from 'react';
import { Helmet } from 'react-helmet';
import { RequestForm } from '../../../components/Form/RequestForm/RequestForm';
import { Card } from '../../../components/Layout/Card/Card';
import { Container } from '../../../components/Layout/Container/Container';
import { UnsortedList } from '../../../components/Layout/List/UnsortedList';
import { Section } from '../../../components/Layout/Section/Section';
import { Title } from '../../../components/Layout/Title/Title';
import { PageWithNavigation } from '../../PageWithNavigation';


export const Bronze: React.FC = () => {

  const bronzeChecks = [
    'es das Seepferdchen erworben hat',
    'es regelmäßig nach dem Seepferdchen im Wasser war',
    'es mind. 2 Bahnen (50m) am Stück sicher schwimmt',
    'es mind. 6 Jahre ist und keine Angst vor Wasser hat',
    'es tauchen kann (idealerweise schon unter Wasser blubbert und die Augen aufmacht) Es sich traut im großen Becken nach einem Ring zu tauchen und keine Angst hat vom Startblock ins Wasser zu springen',
    'es Spaß im Wasser und Lust auf das Bronzeabzeichen hat',
    'es sich gut konzentrieren kann, sich gut in eine Gruppe einfügt (auch ohne Eltern), ohne Hilfe auf Toilette geht und motorisch gut drauf ist',
  ];

  const bronzeCourseOptions = [
    'Kurs I: Dienstag (14:30-16:00) - 8 Kurstermine',
    'Kurs II: Mittwoch (14:45-16:15) - 8 Kurstermine',
    'Kurs III: Donnerstag (15:45-17:15) - 8 Kurstermine',
    'Kurs IV: Freitags (15:15-16:45) - 8 Kurstermine (dieser Kurs wird nur bei freien Kapazitäten angeboten)',
    'ich bin flexibel',
  ];

  return (
      <PageWithNavigation>
        <Helmet title="Bronzekurse für Kinder in Winsen">
          <meta name="description"
                content="Mache dein Silber Abzeichen in Winsen Luhe, im Freizeitbad-die-Insel. Schwimmkurs für Kinder ab 6 Jahren."/>
        </Helmet>

        <Container>
          <div className="information-container">
            <Title className="title-box" title="Bronze" subtitle="Für Kinder ab 6 Jahren"/>

            <div className="overview-box">
              <h2 className="title is-4">Der Kurs im Überblick</h2>
              <UnsortedList>
                <li>Ziel des Kurses: Erlernen/ Vertiefen der korrekten Schwimmtechnik, Tieftauchen im großen Becken,
                  Springen vom Block und Delfinsprünge (Kopfsprünge) vom Rand
                </li>
                <li>Mit tollen Übungen, Spaß und Motivation auf zum Bronzeabzeichen</li>
                <li>1 x in der Woche à 1 Stunde</li>
                <li>8 Termine (Kurstermine Mi und Do ab 15:00 Uhr)</li>
                <li>Eltern nicht dabei, außer ein <a href="/faq#eltern">Begleitelternteil</a> pro Gruppe</li>
                <li>Gruppengröße: 8 Kinder</li>
                <li>Preis: 150€ pro Kind (inklusive Eintritt, abgetrennten Schwimmbereich und Abzeichen)</li>
              </UnsortedList>
            </div>

            <div className="logo-box">
              <picture>
                <source type="image/avif" srcSet="/img/abzeichen/bronze_400x400.avif"/>
                <img src="/img/abzeichen/bronze_400x400.png" alt="" width={ 400 } height={ 400 }/>
              </picture>
            </div>
          </div>
        </Container>

        <Container>
          <Card>
            <RequestForm
                course="Bronze"
                checklist={ {
                  title: 'Bitte kreuze alles an, damit wir sichergehen können, dass dein Kind bereit für den Bronzekurs ist.',
                  checks: bronzeChecks,
                } }
                courseOptions={ {
                  title: 'Aktuelle Kurse',
                  subtitle: 'Bitte sag uns schon jetzt, welche Zeiten dir am besten passen würden (Mehrfachauswahl möglich)',
                  checks: bronzeCourseOptions,
                } }
            />
          </Card>
        </Container>

        <Container className="course-footer">
          <Section title="Bronze Abzeichen">
            <div className="is-flex-1">
              <p>Um dieses Abzeichen zu erhalten müssen folgende Punkte erfüllt werden:</p>
              <UnsortedList>
                <li>Sprung kopfwärts vom Beckenrand und 15 Minuten Schwimmen. In dieser Zeit sind mind. 200m
                  zurückzulegen, davon 150m in Bauch- oder Rückenlage in einer erkennbaren Schwimmart und 50m in der
                  anderen Körperlage.
                </li>
                <li>Einmal ca. 2m Tieftauchen von der Wasseroberfläche mit Heraufholen eines kleinen Gegenstandes.</li>
                <li>Paketsprung vom Startblock.</li>
                <li>Kenntnis von Baderegeln.</li>
              </UnsortedList>
            </div>
            <picture>
              <source type="image/avif" srcSet="/img/abzeichen/bronze_400x400.avif"
                      sizes="(max-width: 735px) 100vw, 736px"/>
              <img src="/img/abzeichen/bronze_400x400.png" alt="" width={ 200 } height={ 200 }/>
            </picture>
          </Section>

          <Section>
            <p>Es gibt keine Garantie für das jeweilige Abzeichen, da jedes Kind mit seiner
              Entwicklung
              einzigartig ist. Wir holen dein Kind immer da ab, wo es steht und geben unser Bestes, es mit einer
              sicheren
              Schwimmtechnik zum jeweiligen Abzeichen zu führen.</p>
          </Section>

        </Container>

      </PageWithNavigation>
  )
      ;
};