import React from 'react';
import { v4 as uuid } from 'uuid';

interface SelectProps<T> {
  id?: string;
  label: string;
  options: T[];
  selected: T;
  labelFunc: (value: T) => string;
  onSelect: (value: T) => void;
}

export const Select: React.FC<SelectProps<any>> = <T, >({
                                                          id,
                                                          label,
                                                          options,
                                                          selected,
                                                          labelFunc,
                                                          onSelect,
                                                        }: SelectProps<T>) => {
  const inputId = id ?? uuid();

  return (
      <div className="field">
        <label className="label" htmlFor={ inputId }>
          { label }
        </label>
        <div className="select">
          <select id={ inputId }>
            { options.map(it => (
                <option
                    key={ labelFunc(it) }
                    selected={ it === selected }
                    onSelect={ () => onSelect(it) }
                >{ labelFunc(it) }</option>
            )) }
          </select>
        </div>
      </div>
  );
};