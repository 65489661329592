import classNames from 'classnames';
import React, { ReactNode } from 'react';
import './Modal.scss';

interface ModalProps {
  show: boolean;
  title: string;
  onClose: () => void;
  primaryLabel?: string;
  primaryAction: () => void;
  children: ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
                                              show,
                                              title,
                                              onClose,
                                              primaryLabel,
                                              primaryAction,
                                              children,
                                            }: ModalProps) => {


  return (
      <div className={ classNames('Modal modal', { 'is-active': show }) }>
        <div className="modal-background"/>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{ title }</p>
            <button onClick={ onClose } className="delete" aria-label="close"></button>
          </header>
          <section className="modal-card-body">
            { children }
          </section>
          <footer className="modal-card-foot">
            <button onClick={ primaryAction } className="button is-success">{ primaryLabel ?? 'OK' }</button>
            <button onClick={ onClose } className="button">Abbrechen</button>
          </footer>
        </div>
      </div>
  );
};