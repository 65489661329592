import React from 'react';
import { BrowserRouter, Route, Routes as RouterRoutes } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { Routes } from './constants/Routes';
import { AcceptAgb } from './pages/agb/AcceptAgb';
import { Agb } from './pages/agb/Agb';
import { Datenschutz } from './pages/datenschutz/Datenschutz';
import { FaqPage } from './pages/faq/FaqPage';
import { HomePage } from './pages/home/HomePage';
import { Impressum } from './pages/impressum/Impressum';
import { NotFoundPage } from './pages/not-found/NotFoundPage';
import { OfferAccepted } from './pages/offers/Accepted/Accepted';
import { Adult } from './pages/offers/Adult/Adult';
import { Bronze } from './pages/offers/Bronze/Bronze';
import { Gold } from './pages/offers/Gold/Gold';
import { OffersPage } from './pages/offers/OffersPage';
import { Robby } from './pages/offers/Robby/Robby';
import { Seepferdchen } from './pages/offers/Seepferdchen/Seepferdchen';
import { Silver } from './pages/offers/Silver/Silver';
import { ManualRequest } from './pages/requests/manual/ManualRequest';
import { RequestsPage } from './pages/requests/RequestsPage';

export const AppRouter = React.memo(() => (
    <BrowserRouter>
      <RouterRoutes>
        <Route path={ Routes.Home.path } Component={ HomePage }/>
        <Route path={ Routes.Agb.path } Component={ Agb }/>
        <Route path={ Routes.AcceptAgb.path } Component={ AcceptAgb }/>
        <Route path={ Routes.FAQ.path } Component={ FaqPage }/>
        <Route path={ Routes.Impressum.path } Component={ Impressum }/>
        <Route path={ Routes.Datenschutz.path } Component={ Datenschutz }/>
        <Route path={ Routes.Offers.path } Component={ OffersPage }/>
        <Route path={ Routes.OffersRobby.path } Component={ Robby }/>
        <Route path={ Routes.OffersSeepferdchen.path } Component={ Seepferdchen }/>
        <Route path={ Routes.OffersBronze.path } Component={ Bronze }/>
        <Route path={ Routes.OffersSilver.path } Component={ Silver }/>
        <Route path={ Routes.OffersGold.path } Component={ Gold }/>
        <Route path={ Routes.OffersAdult.path } Component={ Adult }/>
        <Route path={ Routes.OfferAccepted.path } Component={ OfferAccepted }/>

        <Route path={ Routes.Requests.path } element={ <PrivateRoute><RequestsPage/></PrivateRoute> }/>
        <Route path={ Routes.ManualRequest.path } element={ <PrivateRoute><ManualRequest/></PrivateRoute> }/>
        
        <Route path={ Routes.NotFound.path } Component={ NotFoundPage } />
      </RouterRoutes>
    </BrowserRouter>
));