import React, { createRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import { Routes } from '../../constants/Routes';
import { Button } from '../Form/Button/Button';
import './Header.scss';
import { NavItem } from './NavItem/NavItem';

export const Header: React.FC = () => {
  const auth = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const burgerMenuButton = createRef<HTMLButtonElement>();
  const menu = createRef<HTMLDivElement>();

  const toggleMenu = () => {
    burgerMenuButton.current?.classList.toggle('is-active');
    menu.current?.classList.toggle('is-active');
    setShowMenu(current => !current);
  };

  const logout = () => {
    auth.signoutRedirect({
      post_logout_redirect_uri: window.location.href
    })
  }
  
  return (
      <header className="navbar has-shadow">
        <div className="container px-3">
          <div className="navbar-brand">
            <Link to={ Routes.Home.path } className="back-home">
              <picture>
                <source type="image/avif" srcSet="/img/logo-mobile.avif" />
                <img src="/img/logo-mobile.png" alt="Logo Schwimmschule - Zur Homepage" width={ 150 } height={52.42} />
              </picture>
            </Link>

            <button
                ref={ burgerMenuButton }
                className="navbar-burger is-red"
                aria-label={ showMenu ? 'Hauptmenü verstecken' : 'Hauptmenü anzeigen' }
                aria-expanded="false"
                onClick={ toggleMenu }
            >
              <span aria-hidden="true"/>
              <span aria-hidden="true"/>
              <span aria-hidden="true"/>
            </button>
          </div>

          <div ref={ menu } className="navbar-menu">
            <nav aria-label="Hauptnavigation" className="navbar-start">
              <ul role="menubar">
                <li role="presentation">
                  <NavItem to={ Routes.Home.path }>Home</NavItem>
                </li>
                <li role="presentation">
                  <NavItem to={ Routes.Offers.path }>Schwimmkurse</NavItem>
                </li>
                <li role="presentation">
                  <NavItem to={ Routes.FAQ.path }>FAQ</NavItem>
                </li>
                { auth.isAuthenticated && (
                    <li role="presentation">
                      <NavItem to={ Routes.Requests.path }>Anfragen</NavItem>
                    </li>    
                )}
              </ul>
            </nav>

            {auth.isLoading && (
                <span>Lade...</span>
            )}
            
            { auth.isAuthenticated && (
                <nav aria-label="Benutzer" className="navbar-end">
                  <div className="user-nav">
                  <span>
                    Hallo { auth.user?.profile.given_name }
                  </span>
                  <Button onClick={ logout }>
                    Abmelden
                  </Button>
                  </div>
                </nav>
            ) }

          </div>
        </div>
      </header>
  );
}; 