import classNames from 'classnames';
import React from 'react';
import './Card.scss';

interface CardProps {
  id?: string;
  className?: string;
  title?: string;
  subtitle?: string;
  imgSrc?: string;
  avifSrc?: string;
  imgAlt?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({
                                            id,
                                            className,
                                            title,
                                            subtitle,
                                            imgSrc,
                                            avifSrc,
                                            imgAlt = '',
                                            onClick,
                                            children,
                                          }: CardProps) => {

  return (
      <div id={ id } className={ classNames('Card box p-4', className) } onClick={ onClick }>
        { title && <div className="title has-text-centered mt-3">{ title }</div> }
        { (imgSrc || avifSrc) && (
            <picture className="image is-square mb-3">
              { avifSrc && <source type="image/avif" srcSet={ avifSrc }/> }
              { imgSrc && <img src={ imgSrc } alt={ imgAlt }/> }
            </picture>
        ) }
        { subtitle && <div className="subtitle has-text-centered">{ subtitle }</div> }
        
        { children }
      </div>
  );
};