import React, { ReactNode } from 'react';


interface TabProps {
  title: ReactNode;
  active?: boolean;
  children: ReactNode;
}

export const Tab: React.FC<TabProps> = () => (
    <span>please use Tab with Tabs</span>
);