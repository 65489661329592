import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Form/Button/Button';
import { Container } from '../../components/Layout/Container/Container';
import { Title } from '../../components/Layout/Title/Title';
import { Routes } from '../../constants/Routes';
import { useHttp } from '../../http/http';
import { useAgbs } from '../../zustand/agb.store';
import { PageWithNavigation } from '../PageWithNavigation';

export const AcceptAgb = () => {
  const { POST } = useHttp();
  let { id } = useParams();
  const { acceptAgbStatus, acceptAgbPending, error, acceptAgb } = useAgbs();

  useEffect(() => {
    if (id) {
      acceptAgb(POST, id);
    }
  }, [id]);

  return (
      <PageWithNavigation>
        <Helmet title={ Routes.Agb.title }>
          <meta name="robots" content="noindex, nofollow"/>
        </Helmet>

        <Container>
          <Title title="Allgemeine Geschäftsbedingungen (AGB) akzeptieren"/>
          {
              acceptAgbPending && <>AGBs werden akzeptiert...</>
          }
          {
              error && (
                  <article className="message is-danger">
                    <div className="message-header">
                      <p>Fehler</p>
                    </div>
                    <div className="message-body">
                      { error }
                    </div>
                  </article>
              )
          }
          {
              acceptAgbStatus && (
                  <>
                    <p className="block">Vielen Dank {acceptAgbStatus.firstname}, dass du den AGB zugestimmt hast.</p>
                    <p className="block">Bald kann es losgehen mit dem Kurs: <strong>{acceptAgbStatus.course}.</strong></p>
                    <p className="block">Wir wünschen dir bis dahin ganz viel Spaß im Wasser</p>
                    <p className="block">Kathrin Schulz und Team</p>
                    <Button color="primary" href="/">
                      Zur Startseite
                    </Button>
                  </>
              )
          }

        </Container>
      </PageWithNavigation>
  );
};