import React, { ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

interface SectionProps {
  id?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
}

export const Section: React.FC<SectionProps> = ({ id, title, subtitle, children }: SectionProps) => {
  const sectionId = id ?? uuid();

  return (
      <section aria-labelledby={ sectionId } className="Section content mb-6">
        <h2 id={ sectionId } className="title is-3 is-4-mobile">{ title }</h2>
        {
            subtitle && <p className="subtitle is-spaced">{ subtitle }</p>
        }
        <div className="Section-content">
          { children }
        </div>
      </section>
  );
};