import React, { ReactNode } from 'react';
import { Row } from './Row';

interface EmptyRowProps {
  children: ReactNode;
}

export const EmptyRow: React.FC<EmptyRowProps> = ({ children }: EmptyRowProps) => (
    <Row>
      <td colSpan={ 999 }>
        <span className="is-flex is-justify-content-center">
          { children }
        </span>
      </td>
    </Row>
);