import classNames from 'classnames';
import React, { ReactNode } from 'react';


interface TitleProps {
  className?: string;
  title: ReactNode;
  subtitle?: ReactNode;
}

export const Title: React.FC<TitleProps> = ({ className, title, subtitle }: TitleProps) => (
    <div className={ classNames("block", className) }>
      <h1 className="title is-size-1-desktop is-size-3-mobile">{ title }</h1>
      {
          subtitle && <p className="subtitle is-spaced">{ subtitle }</p>
      }
    </div>
);