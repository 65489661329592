import { User } from 'oidc-client-ts';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState(
      {},
      document.title,
      window.location.pathname,
  );
};

const oidcConfig: AuthProviderProps = {
  authority: 'https://login.schwimmschule-schulz.de/realms/schwimmschule-schulz',
  redirect_uri: '/',
  client_id: 'schwimmschule-web',
  client_secret: 'okPvuYaYq9jMlsImVQnsg14IWjcd3yco',
  response_type: 'code',
  scope: 'openid profile email',
  onSigninCallback,
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <AuthProvider { ...oidcConfig }>
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
