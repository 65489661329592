import React, { ReactNode, useState } from 'react';
import { Modal } from '../../Layout/Modal/Modal';
import { Button, ButtonProps } from './Button';
import './Button.scss';

interface ConfirmationButtonProps extends ButtonProps {
  confirmTitle?: string;
  confirmContent: ReactNode;
  confirmLabel?: string;
}

export const ConfirmationButton: React.FC<ConfirmationButtonProps> = (
    {
      confirmTitle,
      confirmContent,
      confirmLabel,
      onClick,
      children,
      ...rest
    }: ConfirmationButtonProps) => {
  const [showModal, setShowModal] = useState(false);

  const showConfirm = () => {
    setShowModal(true);
  };

  const confirm = () => {
    setShowModal(false);
    onClick?.();
  };

  const doNotConfirm = () => {
    setShowModal(false);
  };

  return (
      <>
        <Button onClick={ showConfirm } { ...rest }>
          { children }
        </Button>

        <Modal
            show={ showModal }
            title={ confirmTitle ?? 'Bist du sicher?' }
            onClose={ doNotConfirm }
            primaryLabel={ confirmLabel ?? 'Ja' }
            primaryAction={ confirm }
        >
          { confirmContent }
        </Modal>
      </>
  );


};