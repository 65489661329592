export const groupBy = <ELEMENT_TYPE, KEY_TYPE>(list: ELEMENT_TYPE[], keyFunc: (element: ELEMENT_TYPE) => KEY_TYPE): Map<KEY_TYPE, ELEMENT_TYPE[]> => {
  const m: Map<KEY_TYPE, ELEMENT_TYPE[]> = new Map<KEY_TYPE, ELEMENT_TYPE[]>();
  list.forEach(e => {
    const key: KEY_TYPE = keyFunc(e);
    if (!m.has(key)) {
      m.set(key, []);
    }
    m.get(key)?.push(e);
  });
  return m;
};