import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { HTMLInputTypeAttribute } from 'react';
import { v4 as uuid } from 'uuid';

type InputType = string | number;

interface InputProps<T extends InputType> {
  id?: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  value: T;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute | 'textarea';
  rows?: number;
  onChange?: (next: T) => void;
  onBlur?: () => void;
  prefixIcon?: React.ReactNode;
  error?: string;
  warning?: string;
  valid?: boolean;
}

export const Input: React.FC<InputProps<any>> = <T extends InputType, >({
                                                                          id,
                                                                          label,
                                                                          required,
                                                                          placeholder,
                                                                          value,
                                                                          disabled,
                                                                          type,
                                                                          rows,
                                                                          onChange,
                                                                          onBlur,
                                                                          prefixIcon,
                                                                          error,
                                                                          warning,
                                                                          valid,
                                                                        }: InputProps<T>) => {
  const inputId = id ?? uuid();

  let suffixIcon;
  let errorText;
  let warningText;
  if (!!error) {
    suffixIcon = (
        <span className="icon is-small is-right">
          <FontAwesomeIcon icon={ faExclamationTriangle }/>
        </span>
    );
    errorText = <p className="help has-text-danger">{ error }</p>;
  }
  if (!!warning) {
    suffixIcon = (
        <span className="icon is-small is-right">
          <FontAwesomeIcon icon={ faExclamationTriangle }/>
        </span>
    );
    warningText = <p className="help has-text-black has-background-warning">{ warning }</p>;
  }

  if (!!valid) {
    suffixIcon = (
        <span className="icon is-small is-right">
          <FontAwesomeIcon icon={ faCheck }/>
        </span>
    );
  }

  return (
      <div className="field">
        <label className="label" htmlFor={ inputId }>
          { label }
          { required && <span className="has-text-danger">*</span> }
        </label>
        <div className={ classNames('control', { 'has-icons-left': !!prefixIcon, 'has-icons-right': !!suffixIcon }) }>
          {
            type === 'textarea'
                ? (
                    <textarea
                        id={ inputId }
                        className={ classNames('textarea', { 'is-danger': !!error, 'is-warning': !!warning, 'is-success': !!valid }) }
                        value={ value }
                        rows={ rows }
                        placeholder={ placeholder }
                        onChange={ e => onChange?.(e.target.value as T) }
                        onBlur={ onBlur }
                        disabled={ disabled }
                    ></textarea>
                )
                : (
                    <input
                        id={ inputId }
                        className={ classNames('input', { 'is-danger': !!error, 'is-warning': !!warning, 'is-success': !!valid }) }
                        type={ type ?? 'text' }
                        value={ value }
                        placeholder={ placeholder }
                        onChange={ e => onChange?.(e.target.value as T) }
                        onBlur={ onBlur }
                        disabled={ disabled }
                    />
                )
          }

          { prefixIcon }
          { suffixIcon }
        </div>
        { errorText }
        { warningText }
      </div>
  );
};
