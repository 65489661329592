import React from 'react';
import { Helmet } from 'react-helmet';
import { RequestForm } from '../../../components/Form/RequestForm/RequestForm';
import { Card } from '../../../components/Layout/Card/Card';
import { Container } from '../../../components/Layout/Container/Container';
import { UnsortedList } from '../../../components/Layout/List/UnsortedList';
import { PageWithNavigation } from '../../PageWithNavigation';


export const Robby: React.FC = () => {

  return (
      <PageWithNavigation>
        <Helmet title="Wassergewöhnungskurse für Kinder in Winsen">
          <meta name="description" content="In diesem Schwimmkurs für Kinder ab 3,5 Jahren nehmen wir die Angst vor Wasser im Gesicht. Der Kurs findet in Winsen Luhe, im Freizeitbad-die-Insel statt." />
        </Helmet>

        <Container>
          <div className="information-container">
            <div className="title-box">
              <h1 className="title is-1">Wasser&shy;gewöhnung</h1>
              <p className="subtitle is-spaced">Für Kinder ab 3,5 Jahren</p>
            </div>

            <div className="overview-box">
              <h2 className="title is-4">Der Kurs im Überblick</h2>
              <UnsortedList>
                <li>Ziel des Kurses: Keine Angst vor Wasser im Gesicht, Blubbern, Tauchen, Gleiten</li>
                <li>Mit Spaß, Spielen und Know-How zur richtigen Wasserratte werden</li>
                <li>Eltern sind beim Umziehen dabei, sowie ein Begleitelternteil pro Gruppe in der Halle</li>
                <li>Mittwochs zwischen 17-18 Uhr</li>
                <li>Kleine Gruppen à 6 Kindern</li>
                <li>8 Einheiten à 30 Minuten</li>
                <li>Keine Vorerfahrung nötig</li>
                <li>Mit Abschlussurkunde</li>
                <li>Kosten 100€ pro Kind (inkl. Eintritt, abgetrennter Schwimmbereich, Abschlussurkunde)</li>
              </UnsortedList>
            </div>

            <div className="logo-box">
              <picture>
                <source type="image/avif" srcSet="/img/abzeichen/wassergewoehnung_400x400.avif" />
                <img src="/img/abzeichen/wassergewoehnung_400x400.png" alt="" width={ 400 } height={ 400 } />
              </picture>
            </div>
          </div>

          <Card>
            <RequestForm course="Wassergewöhnung"/>
          </Card>
        </Container>

      </PageWithNavigation>
  );
};