import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '../../components/Layout/Container/Container';
import { Section } from '../../components/Layout/Section/Section';
import { Title } from '../../components/Layout/Title/Title';
import { Routes } from '../../constants/Routes';
import { PageWithNavigation } from '../PageWithNavigation';

export const Impressum: React.FC = () => {
  
  return (
      <PageWithNavigation>
        <Helmet title={Routes.Impressum.title}>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        
        <Container>
          <Title title="Impressum" subtitle="Betreiber" />
          
          <Section>
            Schwimmschule Kathrin Schulz<br />
            Einzelunternehmen<br />
            Anschrift:<br />
            Sperberweg 43<br />
            21423 Winsen Luhe<br />
            Kontakt:<br />
            Tel: <a href="tel:016094844620">0160 94 84 46 20</a><br />
            E-Mail: <a href="mailto:mail@schwimmschule-schulz.de">mail@schwimmschule-schulz.de</a>
          </Section>
        </Container>
      </PageWithNavigation>
  )
}