import React from 'react';
import { Helmet } from 'react-helmet';
import { RequestForm } from '../../../components/Form/RequestForm/RequestForm';
import { Card } from '../../../components/Layout/Card/Card';
import { Container } from '../../../components/Layout/Container/Container';
import { UnsortedList } from '../../../components/Layout/List/UnsortedList';
import { Section } from '../../../components/Layout/Section/Section';
import { Title } from '../../../components/Layout/Title/Title';
import { PageWithNavigation } from '../../PageWithNavigation';
import '../OfferPage/OfferPage.scss';

export const Silver: React.FC = () => {

  const silverChecks = [
    'es das Bronzeabzeichen erworben hat',
    'es regelmäßig nach dem Bronzeabzeichen im Wasser war',
    'es mind. 8 Bahnen (200m) am Stück sicher schwimmt',
    'es auf dem Rücken schwimmen kann (die korrekte Technik wird im Kurs erlernt)',
    'es mind. 7 Jahre ist und keine Angst vor Wasser hat',
    'es nach einem Ring im großen Becken tauchen kann',
    'und schon mind. 5m weit taucht',
    'es gerne vom Startblock ins Wasser springt',
    'es Spaß im Wasser hat und Lust auf das Silberabzeichen hat',
    'es sich gut konzentrieren kann, sich gut in eine Gruppe einfügt und motorisch gut drauf ist',
  ];

  return (
      <PageWithNavigation>
        <Helmet title="Silberkurse für Kinder in Winsen">
          <meta name="description" content="Mache dein Silber Abzeichen in Winsen Luhe, im Freizeitbad-die-Insel. Schwimmkurs für Kinder ab 7 Jahren." />
        </Helmet>

        <Container>
          <div className="information-container">
            <Title className="title-box" title="Silber" subtitle="Für Kinder ab 7 Jahren"/>

            <div className="overview-box">
              <h2 className="title is-4">Der Kurs im Überblick</h2>
              <UnsortedList>
                <li>Ziel des Kurses: Erlernen des professionellen Brust und Rückenschwimmens, alle Anforderungen an das
                  Silberabzeichen zu meistern
                </li>
                <li>1 x in der Woche à 1 Stunde</li>
                <li>8 Termine (Kurstermine Mi und Do ab 17:00 Uhr)</li>
                <li>Eltern nicht dabei, außer ein <a href="/faq#eltern">Begleitelternteil</a> pro Gruppe</li>
                <li>Gruppengröße: 8 Kinder</li>
                <li>Preis: 150€ pro Kind (inklusive Eintritt, abgetrennten Schwimmbereich und Abzeichen)</li>
              </UnsortedList>
            </div>

            <div className="logo-box">
              <picture>
                <source type="image/avif" srcSet="/img/abzeichen/silber_400x400.avif" />
                <img src="/img/abzeichen/bronze_400x400.png" alt="" width={ 400 } height={ 400 } />
              </picture>
            </div>
          </div>
        </Container>

        <Container>
          <Card>
            <RequestForm
                course="Silber"
                checklist={ {
                  title: 'Bitte kreuze alles an, damit wir sichergehen können, dass dein Kind bereit für den Silberkurs ist.',
                  checks: silverChecks,
                } }
            />
          </Card>
        </Container>

        <Container className="course-footer">
          <Section title="Silber Abzeichen">
            <div className="is-flex-1">
              <p>Um dieses Abzeichen zu erhalten müssen folgende Punkte erfüllt werden:</p>
              <UnsortedList>
                <li>Sprung kopfwärts vom Beckenrand und 20 Minuten Schwimmen. In dieser Zeit sind mind. 400m
                  zurückzulegen, davon 300m in Bauch- oder Rückenlage in einer erkennbaren Schwimmart und 100m in der
                  anderen Körperlage.
                </li>
                <li>Zweimal ca. 2m Tieftauchen von der Wasseroberfläche mit Heraufholen eines kleinen Gegenstandes.</li>
                <li>10m Streckentauchen</li>
                <li>Ein Sprung aus 3m Höhe oder 2 verschiedene Sprünge aus 1m Höhe.</li>
                <li>Kenntnis von Baderegeln und Verhalten zur Selbstrettung.</li>
              </UnsortedList>
            </div>
            <picture>
              <source type="image/avif" srcSet="/img/abzeichen/silber_400x400.avif" />
              <img src="/img/abzeichen/bronze_400x400.png" alt="" width={ 200 } height={ 200 } />
            </picture>
          </Section>

          <Section>
            <p>Es gibt keine Garantie für das jeweilige Abzeichen, da jedes Kind mit seiner
              Entwicklung
              einzigartig ist. Wir holen dein Kind immer da ab, wo es steht und geben unser Bestes, es mit einer
              sicheren
              Schwimmtechnik zum jeweiligen Abzeichen zu führen.</p>
          </Section>
        </Container>
      </PageWithNavigation>
  );
};