import React from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '../../../components/Form/Button/Button';
import { Container } from '../../../components/Layout/Container/Container';
import { UnsortedList } from '../../../components/Layout/List/UnsortedList';
import { Title } from '../../../components/Layout/Title/Title';
import { Routes } from '../../../constants/Routes';
import { PageWithNavigation } from '../../PageWithNavigation';
import './Accepted.scss';

export const OfferAccepted: React.FC = () => {


  return (
      <PageWithNavigation>
        <Helmet title={ Routes.OfferAccepted.title }></Helmet>

        <Container>
          <Title 
              title="Vielen Dank für deine Anfrage"
              subtitle="Du stehst jetzt auf der Warteliste"
          />

          <div className="accepted-content">
            <img src="/img/undraw_checklist__re_2w7v.svg" alt=""/>

            <div className="call-to-action">
              <div className="mb-5">
                <h2 className="title is-3">Wie es weiter geht:</h2>
                <UnsortedList>
                  <li>Wir bestätigen deine Anfrage per E-Mail</li>
                  <li>Wir prüfen deine Anfrage</li>
                  <li>Du wirst einem passenden Kurs zugeteilt</li>
                  <li>Wir melden uns bei dir, sobald ein Kurs erstellt wird</li>
                </UnsortedList>
              </div>

              <Button color="primary" href="/">
                Zur Startseite
              </Button>
            </div>
          </div>
        </Container>
      </PageWithNavigation>
  );
};