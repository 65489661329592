import React, { ReactNode } from 'react';
import { Row } from './Row';

interface ErrorRowProps {
  children: ReactNode;
}

export const ErrorRow: React.FC<ErrorRowProps> = ({ children }: ErrorRowProps) => (
    <Row>
      <td colSpan={ 999 }>
        <span className="is-flex is-justify-content-center">
          { children }
        </span>
      </td>
    </Row>
);