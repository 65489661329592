import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '../../components/Layout/Container/Container';
import { SortedList } from '../../components/Layout/List/SortedList';
import { UnsortedList } from '../../components/Layout/List/UnsortedList';
import { Section } from '../../components/Layout/Section/Section';
import { Title } from '../../components/Layout/Title/Title';
import { Routes } from '../../constants/Routes';
import { PageWithNavigation } from '../PageWithNavigation';

export const Datenschutz = () => {

  return (
      <PageWithNavigation>
        <Helmet title={ Routes.Datenschutz.title }>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>

        <Container>
          <Title title="Datenschutz" subtitle="Allgemeine Datenschutzhinweise & Datenschutzerklärung"/>

          <Section title="Allgemeine Datenschutzhinweise">
            <p className="block">
              die Schwimmschule Schulz – Kathrin Schulz, Sperberweg 43, 21423 Winsen
            </p>
            <p className="block">
              Inhalt:
            </p>
            <SortedList>
              <li><a href="#1">Name und Kontaktdaten der verantwortlichen Stelle</a></li>
              <li><a href="#2">Erhebung und Speicherung personenbezogener Daten; Art, Zweck und Verwendung</a></li>
              <li><a href="#3">Weitergabe von Daten an Dritte</a></li>
              <li><a href="#4">Ihre Rechte als betroffene Person</a></li>
              <li><a href="#5">Ihr Recht auf Widerspruch</a></li>
              <li><a href="#6">Datenverarbeitung über unsere Website</a></li>
            </SortedList>
          </Section>

          <Section id="1" title="1. Name und Kontaktdaten der verantwortlichen Stelle">
            <p className="block">
              Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen
              Stellenwert für die Geschäftsleitung der Schwimmschule Schulz - Frau Kathrin Schulz. Eine Nutzung der
              Internetseiten der Schwimmschule Schulz - Frau Kathrin Schulz ist grundsätzlich ohne jede Angabe
              personenbezogener Daten möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens
              über
              unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten
              erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche
              Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.
            </p>
            <p className="block">
              Die Schwimmschule Schulz - Frau Kathrin Schulz hat als für die Verarbeitung Verantwortlicher zahlreiche
              technische und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese
              Internetseite verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte
              Datenübertragungen grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht
              gewährleistet werden kann. Aus diesem Grund steht es jeder betroffenen Person frei, personenbezogene Daten
              auch auf alternativen Wegen, beispielsweise telefonisch, an uns zu übermitteln.
            </p>
            <p className="block">
              Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der
              Europäischen
              Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:
            </p>
            <p className="block">
              Schwimmschule Schulz – Kathrin Schulz
              Sperberweg 43
              21423 Winsen
              Tel.: 04171-607107
              E-Mail: datenschutz@schwimmschule-schulz.de als verantwortliche Stelle.
            </p>
          </Section>

          <Section id="2" title="2. Erhebung und Speicherung personenbezogener Daten; Art, Zweck und Verwendung">
            <p className="block">
              Wenn Sie uns beauftragen, werden folgende Informationen erhoben:
            </p>
            <UnsortedList>
              <li>Vorname, Nachname</li>
              <li>E-Mail-Adresse</li>
              <li>Telefonnummer (Festnetz und/oder Mobilfunk)</li>
              <li>Vorname, Nachname des Kindes</li>
              <li>Geburtsdatum des Kindes</li>
              <li>Sonstiges selbstgetätigte Anmerkungen</li>
            </UnsortedList>
            <p className="block">
              Außerdem werden alle Informationen erhoben, die für die Erfüllung eines Schwimmkurses mit Ihnen notwendig
              sind.
            </p>
            <p className="block">
              Die Erhebung der personenbezogenen Daten erfolgt,
            </p>
            <UnsortedList>
              <li>um Sie als Kunden identifizieren zu können;</li>
              <li>um Sie angemessen beraten zu können;</li>
              <li>um unsere vertraglichen Pflichten Ihnen gegenüber erfüllen zu können;</li>
              <li>um unseren gesetzlichen Verpflichtungen nachkommen zu können;</li>
              <li>zur Korrespondenz mit Ihnen;</li>
            </UnsortedList>
            <p className="block">
              Die Verarbeitung der personenbezogenen Daten erfolgt anlässlich Ihrer Anfrage bei uns und ist zu den
              genannten Zwecken für die Bearbeitung Ihres Auftrags und für die Erfüllung von Verpflichtungen aus dem
              zugrundeliegenden Vertrag erforderlich.
            </p>
            <p className="block">
              Die erhobenen personenbezogenen Daten werden bis zum Ablauf der gesetzlichen Aufbewahrungspflicht für
              Kaufleute (6, 8 oder 10 Jahre nach Ablauf des Kalenderjahres, in dem das Vertragsverhältnis beendet
              wurde,)
              gespeichert und danach gelöscht. Dies gilt ausnahmsweise nicht, wenn wir aufgrund von steuer- oder
              handelsrechtlichen Aufbewahrungspflichten (gemäß HGB, StGB oder AO) zu einer längeren Speicherung
              verpflichtet sind oder wenn Sie in eine darüber hinausgehende Speicherung eingewilligt haben.
            </p>
          </Section>

          <Section id="3" title="3. Weitergabe von Daten an Dritte">
            <p className="block">
              Eine Übermittlung Ihrer personenbezogenen Daten an Dritte findet grundsätzlich nicht statt. Ausnahmen
              hiervon gelten nur, soweit dies für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
            </p>
          </Section>

          <Section id="4" title="4. Ihre Rechte als betroffene Person">
            <p className="block">
              Ihnen als von der Datenverarbeitung betroffenen Person stehen verschiedene Rechte zu:
            </p>
            <UnsortedList>
              <li>Widerrufsrecht: Von Ihnen erteilte Einwilligungen können Sie jederzeit uns gegenüber widerrufen. Die
                Datenverarbeitung, die auf der widerrufenen Einwilligung beruht, darf dann für die Zukunft nicht mehr
                fortgeführt werden.
              </li>
              <li>Auskunftsrecht: Sie können Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten verlangen.
                Dies gilt insbesondere für die Zwecke der Datenverarbeitungen, die Kategorien der personenbezogenen
                Daten,
                ggf. die Kategorien von Empfängern, die Speicherdauer, sowie ggf. die Herkunft Ihrer Daten
              </li>
              <li>Berichtigungsrecht: Sie können die Berichtigung unrichtiger oder die Vervollständigung Ihrer bei uns
                gespeicherten personenbezogenen Daten verlangen.
              </li>
              <li>Löschungsrecht: Sie können die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten verlangen,
                soweit deren Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur
                Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur
                Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.
              </li>
              <li>Recht auf Einschränkung der Verarbeitung: Sie können die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die
                Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen. Außerdem steht Ihnen dieses Recht zu,
                wenn
                wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen benötigen. Darüber hinaus haben Sie dieses Recht, wenn Sie Widerspruch gegen die
                Verarbeitung Ihrer personenbezogenen Daten eingelegt haben;
              </li>
              <li>Recht auf Datenübertragbarkeit: Sie können verlangen, dass wir Ihnen Ihre personenbezogenen Daten, die
                Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format
                übermitteln. Alternativ können Sie die direkte Übermittlung der von Ihnen uns bereitgestellten
                personenbezogenen Daten an einen anderen Verantwortlichen verlangen, soweit dies möglich ist.
              </li>
            </UnsortedList>
          </Section>

          <Section id="5" title="5. Ihr Recht auf Widerspruch">
            <p className="block">
              Sofern wir Ihre personenbezogenen Daten auf Basis eines berechtigten Interesses verarbeiten, haben Sie das
              Recht, Widerspruch gegen diese Verarbeitung einzulegen. Möchten Sie von Ihrem Widerspruchsrecht Gebrauch
              machen, genügt eine Mitteilung in Textform. Sie können uns also gerne anschreiben oder sich per E-Mail an
              uns wenden. Unsere Kontaktdaten finden Sie unter Punkt 1. dieser Datenschutzhinweise.
            </p>
          </Section>

          <Section id="6" title="6. Datenverarbeitung online">
            <p className="block">
              Auch über unsere Internetseite unter schwimmschule-schulz.de erfolgt die Verarbeitung bestimmter
              personenbezogener Daten, u.a. der IP-Adresse der Website- Besucher.
              Erfassung von allgemeinen Daten und Informationen
              Die Internetseite der Schwimmschule Schulz - Frau Kathrin Schulz erfasst mit jedem Aufruf der
              Internetseite
              durch eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und
              Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert.
              Erfasst werden können die (1) verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System
              verwendete Betriebssystem, (3) die Internetseite, von welcher ein zugreifendes System auf unsere
              Internetseite gelangt (sogenannte Referrer), (4) die Unterwebseiten, welche über ein zugreifendes System
              auf
              unserer Internetseite angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die
              Internetseite, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der Internet- Service-Provider des
              zugreifenden Systems und (8) sonstige ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle
              von
              Angriffen auf unsere informationstechnologischen Systeme dienen.
            </p>

            <p className="block">
              Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die Schwimmschule Schulz - Frau Kathrin
              Schulz keine Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um (1)
              die Inhalte unserer Internetseite korrekt auszuliefern, (2) die Inhalte unserer Internetseite sowie die
              Werbung für diese zu optimieren, (3) die dauerhafte Funktionsfähigkeit unserer informationstechnologischen
              Systeme und der Technik unserer Internetseite zu gewährleisten sowie (4) um Strafverfolgungsbehörden im
              Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen. Diese anonym
              erhobenen Daten und Informationen werden durch die Schwimmschule Thieme - Frau Sarah Kienast daher
              einerseits statistisch und ferner mit dem Ziel ausgewertet, den Datenschutz und die Datensicherheit in
              unserem Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau für die von uns verarbeiteten
              personenbezogenen Daten sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt von allen
              durch eine betroffene Person angegebenen personenbezogenen Daten gespeichert.
              Kontaktmöglichkeit über die Internetseite
              Die Internetseite der Schwimmschule Schulz - Frau Kathrin Schulz enthält aufgrund von gesetzlichen
              Vorschriften Angaben, die eine schnelle elektronische Kontaktaufnahme zu unserem Unternehmen sowie eine
              unmittelbare Kommunikation mit uns ermöglichen, was ebenfalls eine allgemeine Adresse der sogenannten
              elektronischen Post (E-Mail-Adresse) umfasst. Sofern eine betroffene Person per E- Mail oder über ein
              Kontaktformular den Kontakt mit dem für die Verarbeitung Verantwortlichen aufnimmt, werden die von der
              betroffenen Person übermittelten personenbezogenen Daten automatisch gespeichert. Solche auf freiwilliger
              Basis von einer betroffenen Person an den für die Verarbeitung Verantwortlichen übermittelten
              personenbezogenen Daten werden für Zwecke der Bearbeitung oder der Kontaktaufnahme zur betroffenen Person
              gespeichert. Es erfolgt keine Weitergabe dieser personenbezogenen Daten an Dritte.
              Routinemäßige Löschung und Sperrung von personenbezogenen Daten
              Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der betroffenen
              Person nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies
              durch den Europäischen Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder
              Vorschriften, welchen der für die Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
            </p>

            <p className="block">
              Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder
              einem
              anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten
              routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
            </p>
          </Section>

        </Container>
      </PageWithNavigation>
  );
};