import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';

interface TabsProps {
  children: ReactElement[];
}

export const Tabs: React.FC<TabsProps> = ({ children }: TabsProps) => {
  const [activeIndex, setActiveIndex] = useState(0);


  return (
      <>
        <div className="tabs is-boxed">
          <ul>
            { children.map((tab, i) => (
                <li
                    key={ tab.props.title }
                    className={ classNames({ 'is-active': activeIndex === i }) }
                    onClick={ () => setActiveIndex(i) }
                >
                  <a>
                    { tab.props.title }
                  </a>
                </li>
            )) }
          </ul>
        </div>

        <div className="tabs-content">
          { children[activeIndex]?.props.children }
        </div>
      </>
  );
};
