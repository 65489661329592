import React from 'react';
import { v4 as uuid } from 'uuid';
import { create } from 'zustand';

export interface Notification {
  id: string;
  type: 'success' | 'error';
  message: React.ReactNode;
}


interface NotificationsStore {
  notifications: Notification[];
  showSuccessNotification: (message: string) => void;
  showErrorNotification: (message: string) => void;
  removeNotification: (id: string) => void;
}

export const useNotifications = create<NotificationsStore>(set => ({
  notifications: [],
  showSuccessNotification: (message: string) => {
    const next: Notification = {
      id: uuid(),
      type: 'success',
      message,
    };
    set(state => ({ notifications: [...state.notifications, next] }));
  },
  showErrorNotification: (message: string) => {
    const next: Notification = {
      id: uuid(),
      type: 'error',
      message,
    };
    set(state => ({ notifications: [...state.notifications, next] }));
  },
  removeNotification: (id: string) => {
    set(state => ({ notifications: [...state.notifications.filter(it => it.id !== id)] }));
  },
}));