import React from 'react';
import './OfferListItem.scss';

interface OfferBoxProps {
  title: string;
  subtitle: string;
  offerPath: string;
  imgSrc: string;
  avifSrc: string;
}

export const OfferListItem: React.FC<OfferBoxProps> = ({
                                                         title,
                                                         subtitle,
                                                         offerPath,
                                                         imgSrc,
                                                         avifSrc,
                                                       }: OfferBoxProps) => {

  return (
      <li className="offer-list-item box my-2 p-2">
        <a href={ offerPath }>
          <span className="offer-img">
            { (imgSrc || avifSrc) && (
                <picture className="image is-square mb-3">
                  { avifSrc && <source type="image/avif" srcSet={ avifSrc }/> }
                  { imgSrc && <img src={ imgSrc } alt=""/> }
                </picture>
            ) }
          </span>
          <span className="offer-info">
            <span className="title mb-0 is-size-4">{ title }</span>
            <span>{ subtitle }</span>
          </span>
        </a>
      </li>
  );
};