import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Form/Button/Button';
import { Container } from '../../components/Layout/Container/Container';
import { Title } from '../../components/Layout/Title/Title';
import { Routes } from '../../constants/Routes';
import { PageWithNavigation } from '../PageWithNavigation';
import './NotFoundPage.scss';

export const NotFoundPage = () => {
  const location = useLocation();
  return (
      <PageWithNavigation>
        <Helmet title={ Routes.NotFound.title }>
          <meta name="robots" content="noindex, nofollow"/>
          .
        </Helmet>
        <Container>
          <Title title="Seite nicht gefunden" subtitle="Leider konnten wir deine Anfrage nicht bearbeiten"/>

          <div className="not-found-content">
            <img src="/img/undraw_searching_re_3ra9.svg" alt=""/>
            <p>Wir kennen die Seite <strong>{location.pathname}</strong> leider nicht. Ist hier vielleicht etwas dabei:</p>
            <ul>
              <li><Link to={Routes.Offers.path}>Unser Angebot</Link></li>
              <li><Link to={Routes.FAQ.path}>Antworten auf häufige Fragen</Link></li>
            </ul>
            <p>Ansonten kommst du hier zurück zur Startseite</p>
            <Button color="primary" href="/">
              Zur Startseite
            </Button>
          </div>

        </Container>
      </PageWithNavigation>
  );
};