import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from '../../components/Layout/Container/Container';
import { Section } from '../../components/Layout/Section/Section';
import { Title } from '../../components/Layout/Title/Title';
import { Routes } from '../../constants/Routes';
import { PageWithNavigation } from '../PageWithNavigation';


export const Agb = () => {

  return (
      <PageWithNavigation>
        <Helmet title={ Routes.Agb.title }>
          <meta name="robots" content="noindex, nofollow"/>
        </Helmet>

        <Container>
          <Title title="Allgemeine Geschäftsbedingungen (AGB)"/>

          <Section title="1. Warteliste und Anmeldung">
            <p>Die Anmeldung auf unserer Homepage für die Wartelisten sind unverbindlich und jederzeit widerrufbar.</p>
            <p>Ein Vertrag kommt zustande, sobald von der Schwimmschule Schulz ein konkreter Kurstermin vorgeschlagen
              wird, dieser innerhalb von 24 Stunden bestätigt und somit zugestimmt wird. Eine Zusage verpflichtet zur
              Zahlung der Kursgebühren. Der Anmeldende gibt die Daten wahrheitsgemäß an und erkennt mit seiner
              Zustimmung die Teilnahmebedingungen und unsere, diese Allgemeinen Geschäftsbedingungen an und bestätigt
              damit ferner, dass die Informationen gelesen und akzeptiert wurden.</p>
          </Section>

          <Section title="2. Gesundheit">
            <p>Sofern keine gesundheitlichen Bedenken bestehen, kann jeder am Schwimmunterricht teilnehmen. Mit Ihrer
              Zustimmung bestätigen Sie, dass Ihr Kind bzw. Sie zu Kursbeginn oder zum Einzeltraining unter keinen
              ansteckenden Krankheiten leiden und körperlich belastbar sind. Im Zweifelsfall sprechen Sie bitte mit
              Ihrem Kinderarzt/ Arzt. Kursrelevante körperliche und/oder gesundheitliche Einschränkungen müssen der
              Schwimmschule (Schwimmschule Schulz) schnellstmöglich mitgeteilt werden.</p>
          </Section>

          <Section title={ <>3. Zahlungs&shy;bedingungen</> }>
            <p>Die Kursgebühr ist beim ersten Termin in bar zu entrichten. Bei Nichtzahlung kann der Kursteilnehmer vom
              Kurs ausgeschlossen werden. Das Honorar wird jedoch trotzdem fällig, sollte kein passender Ersatz
              verfügbar sein.
            </p>
          </Section>

          <Section title="4. Rücktritt">
            <p>Die Ab-/Ummeldung muss schriftlich (auch per E-Mail) erfolgen.</p>
            <p>Bei Absage des Kurses bis 15 Tage vor Kursbeginn, wird keine Kursgebühr erhoben. Ab dem 14.Tag vor
              Kursbeginn wird das Honorar nur erstattet, wenn ein passender Ersatz für den entsprechenden Kurs gefunden
              werden kann. Ansonsten werden 100% des Honorars als Ausfallentschädigung fällig. Die Erstattung oder der
              Ersatz ausgefallener Stunden, auch bei Abbruch des Kurses, ist nicht möglich.</p>
          </Section>

          <Section title={ <>5. Mindest&shy;teilnehmerzahl</> }>
            <p>Bei den Schwimmkursen gilt eine Mindestteilnehmerzahl von 6 Teilnehmern. Sollte diese unterschritten
              werden, bietet die Schwimmschule vor Kursbeginn einen Alternativtermin an. Grundsätzlich wird bei der
              Kursbelegung darauf geachtet, die Teilnehmer alters nah und mit ähnlichem Entwicklungsstand zusammen zu
              führen.</p>
          </Section>

          <Section title="6. Ausfall">
            <p>Nicht wahrgenommene Termine von Kursteilnehmern werden nicht rückerstattet und können nicht nachgeholt
              werden. Ungenutzte Leistungen können, selbst mit ärztlichem Attest, nicht ersetzt oder verrechnet werden.
              Ausfall seitens der Schwimmschule: Im Fall einer Verhinderung der Schwimmschule (z.B. wegen technischer
              Defekte, Krankheit des Schwimmlehrers, einer Pandemie oder sonstiger zwingender Gründe bzw. höherer
              Gewalt) können keine Ersatzansprüche seitens der Kursteilnehmer gestellt werden. Bei Krankheit der/des
              Kursleiter/in versucht die Schwimmschule einen Vertretungslehrer zu organisieren.</p>
          </Section>

          <Section title={ <>7. Haftungs&shy;beschränkung</> }>
            <p>Die Haftung für Personen-, Sach- und Vermögensschäden, mit Ausnahme solcher wegen schuldhaften
              Leistungsverzugs, zu vertretender Unmöglichkeit oder verschuldeter Nichterfüllung, beschränkt sich auf
              Vorsatz und grobe Fahrlässigkeit. Um einen reibungslosen Kursablauf gewährleisten zu können, ist es
              notwendig, den Anweisungen der Kursleiter/innen unbedingt zu folgen. Falls dies nicht erfolgt, ist eine
              Fahrlässigkeitshaftung ausgeschlossen. Die Schwimmschule Schulz haftet nicht für Diebstähle. Für mutwillig
              angerichtete Schäden an den Anlagen und Geräten der Schwimmschule haften Eltern für ihre Kinder. Die
              Kursteilnehmer und Begleitperson/en betreten und nutzen die Schwimmeinrichtung auf eigene Gefahr. Der
              direkte Schwimmbeckenbereich darf nicht betreten werden, solange in diesem Bereich kein/e Kursleiter/in
              anwesend ist.</p>
          </Section>

          <Section title="8. Datenschutz">
            <p>Mit Ihrer Anmeldung erklären Sie sich freiwillig damit einverstanden, dass Ihre Daten bei der
              Schwimmschule Schulz elektronisch gespeichert und verarbeitet werden. Ihre Daten werden ausschließlich für
              die Auftragsabwicklung verwendet. Die erteilte Einwilligung kann jederzeit widerrufen werden. Alle
              Informationen zu unserem Datenschutz finden Sie <Link to={ Routes.Datenschutz.path }>hier</Link>.</p>
          </Section>

          <Section title={ <>9. Schluss&shy;bestimmung</> }>
            <p>Sollte eine der Bedingungen nichtig oder unwirksam sein, so bleibt der restliche Vertrag sowie die
              weiteren Teilnahmebedingungen hiervon unberührt. Anstelle dieser nichtigen oder unwirksamen Bedingungen
              treten die gesetzlichen Bedingungen ein.</p>
          </Section>
          
          <div>
            <p>Stand August 2024</p>
            <p>Schwimmschule Schulz</p>
          </div>
        </Container>
      </PageWithNavigation>
  );
};