import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RequestForm } from '../../../components/Form/RequestForm/RequestForm';
import { Select } from '../../../components/Form/Select/Select';
import { Card } from '../../../components/Layout/Card/Card';
import { Container } from '../../../components/Layout/Container/Container';
import { Title } from '../../../components/Layout/Title/Title';
import { Routes } from '../../../constants/Routes';
import { Course } from '../../../data/inquiry.interface';
import { PageWithNavigation } from '../../PageWithNavigation';


export const ManualRequest: React.FC = () => {
  const [course, setCourse] = useState<Course>('Wassergewöhnung');
  const options: Course[] = ['Wassergewöhnung', 'Seepferdchen', 'Bronze', 'Silber', 'Gold'];

  return (
      <PageWithNavigation>
        <Helmet title={ Routes.ManualRequest.title }>
          <meta name="robots" content="noindex, nofollow"/>
        </Helmet>

        <Container>
          <Title title="Anfrage erfassen" subtitle="Hier kannst du Anfragen selbst erfassen, die z.B. über WhatsApp reinkamen."/>

          <Card>
            <Select label="Kurs" options={ options } selected={course} labelFunc={ it => it } onSelect={setCourse}/>

            <RequestForm course={course} manual />
          </Card>
        </Container>

      </PageWithNavigation>
  );
};