import React from 'react';
import { EmptyRow } from '../../components/Table/EmptyRow';
import { ErrorRow } from '../../components/Table/ErrorRow';
import { Table } from '../../components/Table/Table';
import { Course, Inquiry, isAdult } from '../../data/inquiry.interface';
import { useWidth } from '../../hooks/useWidth';
import { InquiryRow } from './InquiryRow';

interface RequestsTableProps {
  course: Course;
  loading: boolean;
  error?: string;
  inquiries: Inquiry[];
}

export const RequestsTable: React.FC<RequestsTableProps> = ({
                                                              course,
                                                              loading,
                                                              error,
                                                              inquiries,
                                                            }: RequestsTableProps) => {
  const { isMobile } = useWidth();

  return (
      <Table>
        <thead>
        <tr>
          <th style={ { width: 30 } }></th>
          { isMobile && isAdult(course) && (
              <th>Anfragende:r</th>
          ) }
          { isMobile && !isAdult(course) && (
              <th>Anfragende:r / Kind</th>
          ) }
          { !isMobile && isAdult(course) && (
              <>
                <th>Anfragende:r</th>
                <th>Alter</th>
                <th>AGBs</th>
                <th></th>
              </>
          ) }
          { !isMobile && !isAdult(course) && (
              <>
                <th>Anfragende:r</th>
                <th>Kind</th>
                <th>Alter</th>
                <th>AGBs</th>
                <th></th>
              </>
          ) }
        </tr>
        </thead>

        <tbody>
        { !loading && error && (
            <ErrorRow>
              Fehler - deine Anfragen konnten nicht geladen werden
            </ErrorRow>
        ) }
        { !loading && !error && inquiries.length === 0 && (
            <EmptyRow>
              Es liegen keine Anfragen vor
            </EmptyRow>
        ) }
        { inquiries.map(it => <InquiryRow key={ it.id } inquiry={ it }/>) }
        </tbody>
      </Table>
  );
};