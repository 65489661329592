import React from 'react';
import { Card } from '../../components/Layout/Card/Card';
import { Container } from '../../components/Layout/Container/Container';
import { Hero } from '../../components/Layout/Hero/Hero';
import { UnsortedList } from '../../components/Layout/List/UnsortedList';


export const Team = () => {

  return (<>
        <Hero title={ <>Dein Schwimmschul&shy;team</> } subtitle="Mit Liebe zum Wasser"/>

        <Container className="team-container">
          <Card title="Kathrin Schulz"
                subtitle="Inhaberin der Schwimmschule Schulz, Seepferdchenkurse und Aufbaukurse, Koordination der Schwimmgruppen"
                imgSrc="/img/team/Kathrin_400x400.png"
                avifSrc="/img/team/Kathrin_400x400.avif"
          >
            <UnsortedList>
              <li>Jg. 1984</li>
              <li>studierte Fitnessökonomin und Gesundheitspräventologin</li>
              <li>Inhaberin Trainer B-Lizenz im Schwimmen</li>
              <li>ehem. Leistungsschwimmerin</li>
              <li>Leitet Schwimmkurse seit 20 Jahren</li>
              <li>Verheiratet und Mama von 2 Söhnen</li>
              <li>Hobbies: Schwimmen und Fitnesssport</li>
            </UnsortedList>
          </Card>

          <Card title="Catharina Tippe"
                subtitle="Seepferdchenkurse und Aufbaukurse"
                imgSrc="/img/team/Catharina_400x400.png"
                avifSrc="/img/team/Catharina_400x400.avif"
          >
            <p className="temporarily-absent">Steht zur Zeit nicht am Beckenrand</p>
            <UnsortedList>
              <li>Jg. 1999</li>
              <li>Lehramtsstudentin</li>
              <li>Aktive Vereinsschwimmerin</li>
              <li>Inhaberin Trainer C-Lizenz im Schwimmen</li>
              <li>Leitet Schwimmkurse seit 2018</li>
              <li>Hobbies: Schwimmen und Bouldern</li>
            </UnsortedList>
          </Card>

          <Card title="Nadine Roßberg"
                subtitle="Wassergewöhnungskurse und Aufbaukurse"
                imgSrc="/img/team/Nadine_400x400.png"
                avifSrc="/img/team/Nadine_400x400.avif"
          >
            <UnsortedList>
              <li>Jg. 1979</li>
              <li>Altenpflegerin</li>
              <li>Verheiratet und Mama eines Sohnes</li>
              <li>Frühere Vereinsschwimmerin</li>
              <li>Lizenzierte Freizeitleiterin für Kinder</li>
              <li>Hobbies: Schwimmen, Yoga, Ju Jutsu und Krafttraining</li>
            </UnsortedList>
          </Card>

          <Card title="Thomas Weselmann"
                subtitle="Seepferdchenkurse und Aufbaukurse (Bronze)"
                imgSrc="/img/team/Thomas_400x400.png"
                avifSrc="/img/team/Thomas_400x400.avif"
          >
            <UnsortedList>
              <li>Jg. 1984</li>
              <li>Realschullehrer mit den Fächern Englisch Sport und Schwimmen</li>
              <li>LSN-Übungsleiter - Anfängerschwimmen</li>
              <li>Verheiratet, 2 Kinder</li>
              <li>Hobbies: Laufen, Tennis, allg. Sport und Reisen</li>
            </UnsortedList>
          </Card>

          <Card title="Bianca Stenzel"
                subtitle="Wassergewöhnungskurse und Seepferdchenkurse"
                imgSrc="/img/team/Bianca_400x400.png"
                avifSrc="/img/team/Bianca_400x400.avif"
          >
            <UnsortedList>
              <li>Jg. 1972</li>
              <li>Sozialpädagogin</li>
              <li>Inhaberin des Rettungsschwimmers Bronze</li>
              <li>2-fach Mami von 2 Söhnen (Wettkampfschwimmern), verheiratet</li>
              <li>Hobbies: Schwimmen, Lesen und ihr Garten</li>
            </UnsortedList>
          </Card>

          <Card title="Sandra Stuhr"
                subtitle="Seepferdchenkurse und Aufbaukurse (Silber)"
                imgSrc="/img/team/Sandra_400x400.png"
                avifSrc="/img/team/Sandra_400x400.avif"
          >
            <UnsortedList>
              <li>Kaufm. Angestellte</li>
              <li>Inhaberin DLRG-Bronze</li>
              <li>Aktive und erfolgreiche Vereins-Mastersschwimmerin in Winsen</li>
              <li>Mama von 2 Söhnen</li>
              <li>Hobbies: Schwimmen, Reisen, Kochen und Backen</li>
            </UnsortedList>
          </Card>
        </Container>
      </>
  );
};