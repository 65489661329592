import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from '../../constants/Routes';
import { Container } from '../Layout/Container/Container';
import './AppFooter.scss';

export const AppFooter: React.FC = () => {
  return (
      <footer className="AppFooter footer">
        <Container>
          <nav aria-label="Weiterführende Links">
            <ul role="menubar">
              { [Routes.Impressum, Routes.Datenschutz, Routes.Agb].map(link => (
                  <li role="presentation">
                    <Link to={ link.path } role="menuitem">
                      { link.title }
                    </Link>
                  </li>
              )) }
            </ul>
            <p className="mt-4 is-size-7">
              { moment().format('YYYY') } | Schwimmschule Kathrin Schulz
            </p>
          </nav>
          <img src="/img/algen/Algenarrangement01.svg" alt="" width={ 250 } height={ 182 }/>
        </Container>
      </footer>
  );
};