import classNames from 'classnames';
import React from 'react';
import './Container.scss';

interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({ className, children }: ContainerProps) => (
    <div className={ classNames('Container container px-4 py-6', className) }>
      { children }
    </div>
);