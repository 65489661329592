import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import './Expandable.scss';

interface ExpandableProps {
  content: ReactNode;
  expandedContent: ReactNode;
}

export const Expandable: React.FC<ExpandableProps> = ({ content, expandedContent }: ExpandableProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
      <div className={ classNames('Expandable', { expanded }) }>
        <div className="expandable-content" onClick={() => setExpanded(!expanded)}>
          <span className="icon expand-icon">
              <FontAwesomeIcon icon={ faChevronDown }/>
          </span>
          <div className="is-flex-1">
            { content }
          </div>
        </div>
        <div className="expanded-content">
          { expandedContent }
        </div>
      </div>
  );
};