import React, { ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import { Expandable } from '../Expandable/Expandable';


interface ExpandableSectionProps {
  id?: string;
  title?: string;
  children?: ReactNode;
}

export const ExpandableSection: React.FC<ExpandableSectionProps> = ({ id, title, children }) => {
  const sectionId = id ?? uuid();

  return (
      <section className="ExpandableSection mb-3" aria-labelledby={ sectionId }>
        <Expandable
            content={ <h2 id={ sectionId } className="title mb-0 is-4 is-5-mobile">{ title }</h2> }
            expandedContent={ children }
        />
      </section>
  );
};