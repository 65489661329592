import React, { ReactNode } from 'react';

interface TableProps {
  children: ReactNode;
}

export const Table: React.FC<TableProps> = ({ children }: TableProps) => {

  return (
      <table className="table is-fullwidth">
        { children }
      </table>
  );
};