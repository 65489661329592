import classNames from 'classnames';
import React from 'react';
import './Button.scss';

export interface ButtonProps {
  id?: string;
  className?: string;
  color?: 'primary' | 'text';
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
  download?: string;
  target?: '_blank';
  children?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = (
    {
      id,
      className,
      color,
      disabled,
      onClick,
      href,
      download,
      target,
      children,
    }: ButtonProps) => {

  const computedClassName = classNames('button', className, {
    'is-primary': color === 'primary',
    'is-text': color === 'text',
  });

  const doOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick?.();
  };

  if (href !== undefined) {
    return (
        <a
            id={ id }
            className={ computedClassName }
            aria-disabled={ disabled }
            href={ href }
            onClick={ doOnClick }
            download={ download }
            target={ target }
        >
          { children }
        </a>
    );
  } else if (onClick !== undefined) {
    return (
        <button
            id={ id }
            className={ computedClassName }
            disabled={ disabled }
            onClick={ doOnClick }
        >
          { children }
        </button>
    );
  } else {
    return (
        <div>Please provide href or onClick</div>
    );
  }


};