import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from '../../components/Layout/Container/Container';
import { Hero } from '../../components/Layout/Hero/Hero';
import { UnsortedList } from '../../components/Layout/List/UnsortedList';
import { ExpandableSection } from '../../components/Layout/Section/ExpandableSection';
import { Title } from '../../components/Layout/Title/Title';
import { Routes } from '../../constants/Routes';
import { PageWithNavigation } from '../PageWithNavigation';


export const FaqPage: React.FC = () => {


  return (
      <PageWithNavigation>
        <Helmet title={ Routes.FAQ.title }>
          <meta name="description" content="Antworten auf die häufigsten Fragen zu unseren Schwimmkursen" />
        </Helmet>

        <Container>
          <Title title="FAQ" subtitle="Antworten auf die häufigsten Fragen findest du hier"/>
        </Container>

        <Hero title="Organisatorische Fragen"/>
        <Container>
          <ExpandableSection title="Wo wird unterrichtet?">
            <p>
              Im Freizeitbad die Insel<br/>
              Bürgerweide 5<br/>
              21423 Winsen (Luhe)<br/>
              <a href="https://www.freizeitbad.stw-winsen.de" target="_blank" rel="noreferrer">www.freizeitbad.stw-winsen.de</a>
            </p>
          </ExpandableSection>
          <ExpandableSection title="Wie melde ich mich für einen Kurs an?">
            <p className="block">
              Wichtig ist, dass dein Kind erstmal auf die Warteliste kommt. Sobald dein Kind erfolgreich auf der
              Warteliste steht, bekommst du eine Bestätigungsmail. Wir bemühen uns, deinem Kind schnellstmöglich einen
              Platz anzubieten. Aus verschiedenen Gründen kann dies ein wenig dauern. Wenn dein Kind die Checkliste für
              den jeweiligen Kurs erfüllt, liegt die Wartezeit derzeit bei etwa einem halben Jahr.
            </p>
            <p className="block">
              du bekommst vorzugsweise via WhatsApp, sonst via Mail von uns Bescheid, sobald wir dein Kind für einen
              Kurs einplanen.
            </p>
            <p className="block">
              Ab dann ist deine Anmeldung verbindlich.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Wann finden die Kurse statt?">
            <p>
              Die jeweiligen Kurstage-und zeiten findest du direkt bei den jeweiligen Schwimmkursen. Grundsätzlich sind
              wir von dienstags bis freitags in der Halle, außer in den Schulferien und an Feiertagen. In den
              Sommerferien bieten wir Seepferdchen- Crash-Kurse an, wenn es unsere Kapazitäten zulassen.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Wo treffen wir uns?">
            <p>Treffpunkt ist im Foyer der Insel, an der Kasse links vorbei vor den Glastüren.</p>
          </ExpandableSection>
          <ExpandableSection id="eltern" title="Dürfen Eltern mit rein?">
            <p>
              Nein, Eltern sind bei unseren Kursen nicht dabei. Nur im Ausnahmefall, der vorher mit den Trainerinnen
              besprochen wurde. Pro Gruppe begleitet uns aber jedes Mal ein Elternteil. Dieses Elternteil hilft beim
              Umziehen in der Umkleidekabine, da die Trainerinnen, außer beim 1.Termin, nicht dabei sind. Außerdem
              begleitet es Toilettengänge während des Kurses.
              Die Koordination der Begleittermine erfolgt über eine WhatsApp Gruppe für den entsprechenden Kurs.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Wie und wann zahlt man die Kursgebühr?">
            <p>
              Am ersten Kurstag wird die jeweilige Kursgebühr von der zuständigen Trainerin eingesammelt. Bitte bringt
              dazu den fälligen Kursbeitrag passend in bar in einem Briefumschlag mit und schreibt den Vor- und
              Nachnamen eures Kindes auf den Umschlag. du erhältst dann eine Quittung von den Trainerinnen.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Gibt es kostenlose Probestunden?">
            <p className="block">
              Nein, da wir sonst den organisatorischen Ablauf unserer Kurse stören würden.
            </p>
            <p className="block">
              Wir sind von dienstags bis freitags im Freizeitbad, fragt gern nach, wenn ihr euch mal einen besonderen
              Kurs ansehen möchtet.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Können Stunden nachgeholt werden?">
            <p>
              Nein, diese Option besteht nicht, weil wir feste Gruppen haben.
              Sollte aber eine Trainerin eine Stunde absagen müssen, wird diese nachgeholt.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Bekomme ich Geld zurück, wenn mein Kind ausfällt?">
            <p>Die Erstattung oder der Ersatz ausgefallener Stunden, auch bei Abbruch des Kurses ist nicht möglich.</p>
          </ExpandableSection>
          <ExpandableSection title="Wann sollte auf den Schwimmunterricht verzichtet werden?">
            <p>Bei Fieber, Durchfall, Ohren- oder Augenentzündung, ansteckende Krankheiten, offene Wunden, etc.</p>
          </ExpandableSection>
        </Container>

        <Hero title="Zu den Kursen"/>
        <Container>
          <ExpandableSection title="Wann kann mein Kind schwimmen lernen?">
            <p className="block">Ab etwa 4 Jahren können sich Kinder kurze Zeit mit Paddeln über Wasser halten.</p>
            <p className="block">In unserer Schwimmschule lernen die Kinder das Brustschwimmen. Da es sich um eine der
              schwierigsten Schwimmtechniken handelt, klappt es häufig erst ab sechs Jahren, weil die Zusammenarbeit von
              Armen und Beinen eine Körperkoordination erfordert, die Kinder oft erst mit dem Schulalter entwickeln.</p>
            <p className="block">Wir beginnen diese Kurse ab einem Alter von mind. 5 Jahren und wenn die Checkliste zum
              Seepferdchenkurs erfüllt wird; bzw. ein Wassergewöhnungskurs erfolgreich im Sinne der Checkliste
              absolviert wurde.</p>
          </ExpandableSection>
          <ExpandableSection title="Gibt es eine Schwimmlern-Garantie?">
            <p className="block">
              Nein. Unsere Erfahrung zeigt, dass die meisten Kinder am Ende des Seepferdchenkurses
              mindestens eine kleine Ecke im Schwimmerbecken zurücklegen können. Nicht alle Kinder schaffen nach unseren
              10 Unterrichtseinheiten die geforderten 25m für das Seepferdchenabzeichen, da jedes Kind in seiner
              Entwicklung einzigartig ist und von uns auch so behandelt und entsprechend gefördert wird.
            </p>
            <p className="block">
              Unser Ziel ist immer die korrekte Technik zu vermitteln. Am Ende des Kurses schauen wir, wie weit die
              Kinder schwimmen können. Entsprechend gibt es dann das Robbenabzeichen, bei dem auf der Urkunde vermerkt
              wird, wie weit das Kind schon geschwommen ist, oder eben das Seepferdchen Abzeichen.
            </p>
            <p className="block">
              Je mehr ihr als Eltern zusätzlich mit euren Kindern ins Wasser geht und mit viel Spaß, Lob und als Vorbild
              voran geht, desto größer sind die Lernerfolge.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Gibt es eine Abzeichen-Prüfung?">
            <p className="block">
              Es gibt keinen konkreten Termin für die Abzeichenprüfung. Somit entfällt der Prüfungsstress. Wenn dein
              Kind die Anforderungen erfüllt, notieren wir dies und am Ende des Kurses erhält es dann das jeweilige
              Abzeichen.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Sind Schwimmbrillen erlaubt?">
            <p className="block">

              Bis zum Bronzeabzeichen sind keine Schwimmbrillen erlaubt. Die Kinder sollen lernen sich unter Wasser ohne
              Schwimmbrille zu orientieren, damit sie es im Ernstfall können.
            </p>
            <p className="block">
              Ab Silberkursen schwimmen die Kinder mit Brillen und Kappen, da wir anfangen, das professionelle
              Brustschwimmen und Rückenschwimmen zu erlernen.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Sind Neoprenanzüge erlaubt?">
            <p className="block">
              Neoprenanzüge verhelfen den Kinder zu mehr Auftrieb im Wasser, daher sollte darauf verzichtet werden.
              Bevor es aber vor lauter Zittern nicht mitmacht ist ein dünner Shorty in unseren Kursen gestattet.
            </p>
            <p className="block">
              Wichtig ist, dass auch weiterhin ohne Neoprenanzug geübt wird.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Was muss mein Kind zum Kurs mitbringen?">
            <p className="block">dein Kind braucht nur:</p>
            <UnsortedList>
              <li>
                Badeanzug oder Bikini / Badehose (bitte eine enganliegende Shorts, keine lange oder zu weite Shorts)
              </li>
              <li>Kinder mit langen Haaren bitte mit Zopf zum den Schwimmstunden schicken, damit die Haare beim Tauchen
                nicht vor den Augen rumhängen
              </li>
              <li>Ein großes Handtuch</li>
              <li>Eine Mütze, besonders an kalten Tagen, da wir die Haare nach der Schwimmstunde nicht föhnen</li>
              <li>1€ für den Schrank</li>
            </UnsortedList>
            <p className="block">
              Bitte nicht mitbringen: Schwimmhilfen, Schwimmbrillen, Schmuck, Spielzeug
            </p>
            <p className="block">
              Wir stellen den Kindern Schwimmnudeln, Bretter, Schlori Schwimmkissen und Tauchringe während unserer
              Stunden zur Verfügung.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Warum liegen die Kinder so tief im Wasser beim Schwimmen?">
            <p className="block">
              Die Kinder strecken den Kopf meist sehr weit nach hinten beim Brustschwimmen. Als Gegenreaktion wird das
              Becken nach unten gedrückt und die Kinder schwimmen wie Bojen. Dieser Schwimmstil ist zu Beginn relativ
              normal und verliert sich mit zunehmendem Alter und verbesserter Technik und mehr Sicherheit.
            </p>
          </ExpandableSection>
          <ExpandableSection
              title="Was passiert, wenn mein Kind am Ende des Kurses noch kein Seepferdchen Abzeichen hat?">
            <p className="block">

              Unser Anspruch ist, innerhalb der Kurszeit das beschriebene Kursziel zu erreichen. Während des Kurses gibt
              es vom Schwimmtrainer eine Einschätzung über den individuellen Entwicklungsstand des Kindes. Wir raten
              immer, noch einmal zusätzlich zu üben. Außerdem stellen wir Übungsvideos zur Verfügung, wo ihr sehen
              könnt, welche Bewegungen geübt werden sollten.
            </p>
            <p className="block">
              Hat dein Kind am Ende des Kurses die Anforderungen an das Seepferdchen Abzeichen nicht erfüllt, sprechen
              wir persönlich mit Dir, wie weiterhin geübt werden sollte. Ebenfalls empfehlen wir Hilfsmittel. Die
              Erfahrung zeigt, dass bei regelmäßigem Üben das Seepferdchen in einigen Wochen bis Monaten erreicht wird
              und vom Bademeister abgenommen werden kann.
            </p>
            <p className="block">
              In sehr wenigen Fällen muss ein weiterer Anfängerkurs gebucht werden.
            </p>
          </ExpandableSection>
          <ExpandableSection title="Warum sollte mein Kind nach dem Seepferdchen noch weiter schwimmen?">
            <p className="block">
              Wenn dein Kind sein Seepferdchen geschafft hat, ist dies natürlich ein großer Erfolg.
            </p>
            <p className="block">
              Nun heißt es, die korrekte beigebrachte Schwimmtechnik zu festigen und zu automatisieren, damit es auch
              weitere Strecken zurücklegen kann als 25m.
            </p>
            <p className="block">
              Wir empfehlen daher mit Freude weiter das nasse Element aufzusuchen, auch mal ins offene Gewässer
              vorzudringen und so oft wie möglich Schwimmbäder zu besuchen. Sobald unsere Checkliste für den nächsten
              Aufbaukurs erfüllt ist, melde dein Kind gern für einen weiteren Kurs bei uns an um das Schwimmen zu
              vertiefen und noch mehr Ausdauer und Sicherheit zu erlangen.
            </p>
            <p className="block">
              In den Aufbaukursen wird es durch neue Übungen, und durch Erlernen neuer Schwimmstile noch vertrauter mit
              dem Element Wasser und wird durch unsere Pyjamaparties erleben, wie es ist mit Kleidung im Wasser zu
              landen.
            </p>
          </ExpandableSection>
        </Container>
      </PageWithNavigation>
  );
};