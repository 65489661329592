import React, { PropsWithChildren } from 'react';
import { AppFooter } from '../components/Footer/AppFooter';
import { Header } from '../components/Header/Header';

export const PageWithNavigation: React.FC<any> = ({ children }: PropsWithChildren) => (
    <>
      <Header/>
      <main>
        { children }
      </main>
      <AppFooter/>
    </>
);