import React from 'react';
import { Helmet } from 'react-helmet';
import { RequestForm } from '../../../components/Form/RequestForm/RequestForm';
import { Card } from '../../../components/Layout/Card/Card';
import { Container } from '../../../components/Layout/Container/Container';
import { UnsortedList } from '../../../components/Layout/List/UnsortedList';
import { Title } from '../../../components/Layout/Title/Title';
import { PageWithNavigation } from '../../PageWithNavigation';
import '../OfferPage/OfferPage.scss';

export const Adult: React.FC = () => {

  const adultChecks = [
    'ich kann noch nicht Schwimmen und möchte dies gerne lernen',
    'ich kann bereits schwimmen und möchte folgende Techniken verbessern:',
    'Brustschwimmen',
    'Kraulen',
    'Rückenschwimmen',
    'ich möchte Gruppenstunden buchen',
    'ich möchte Einzelstunden buchen',
  ];

  return (
      <PageWithNavigation>
        <Helmet title="Schwimmkurse für Erwachsene in Winsen">
          <meta name="description"
                content="Du möchtest als Erwachsener Schwimmen lernen oder einfach deine Technik verbessern, dann komm gerne zu uns nach Winsen Freizeitbad-die-Insel."/>
        </Helmet>

        <Container>
          <div className="information-container">
            <Title className="title-box" title="Schwimmen für Erwachsene" subtitle="Anfänger bis Profis"/>

            <div className="overview-box">
              <h2 className="title is-4">Hinweise für Erwachsenenkurse</h2>
              <UnsortedList>
                <li>Es gibt derzeit kein regelmäßiges Angebot für Erwachsene</li>
                <li>Wenn es die zeitlichen Kapazitäten zulassen und genug Anfragen vorhanden sind, wirst du sofort mit
                  einem Kurs- bzw. Stundenangebot kontaktiert
                </li>
                <li>Gib dazu bitte unbedingt Deine Handynummer an, da wir dich vorzugsweise über WhatsApp anschreiben
                </li>
                <li>Die jeweiligen Preise für Kursangebote oder Einzelstunden richten sich nach Teilnehmerzahl und Stunden, die individuell geplant sind</li>
                <li>Bitte kreuze in der Checkliste an, was auf dich zutrifft</li>
              </UnsortedList>
            </div>

            <div className="logo-box">
              <picture>
                <source type="image/avif" srcSet="/img/logo.avif"/>
                <img src="/img/logo.png" alt="" width={ 400 } height={ 400 }/>
              </picture>
            </div>
          </div>
        </Container>

        <Container>
          <Card>
            <RequestForm
                course="Erwachsene"
                checklist={ {
                  title: 'Bitte kreuze alles zutreffende an, damit wir dir ein passendes Angebot machen können.',
                  subtitle: '',
                  checks: adultChecks,
                } }
            />
          </Card>
        </Container>
      </PageWithNavigation>
  );
};