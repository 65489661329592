import { useAuth } from 'react-oidc-context';
import { EMPTY, from, mergeMap, Observable, of, throwError } from 'rxjs';

export type ACCEPT_TYPE = '--empty--' | 'application/json' | 'application/octet-stream';

const securityHeaders: Record<string, string> = {
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Origin': '*',
};

const authHeader = (token?: string): Record<string, string> => token
    ? {
      'Authorization': 'Bearer ' + token,
    }
    : {};

const acceptHeaders = (accept?: ACCEPT_TYPE): Record<string, string> => {
  if (accept === undefined || accept === '--empty--') {
    return {};
  } else {
    return ({
      'Accept': accept,
    });
  }

};

interface HttpOptions {
  accept?: ACCEPT_TYPE;
  token?: string;
}

const defaultHttpOptions: HttpOptions = {
  accept: 'application/json',
};

export type HTTP_GET = <RESPONSE_TYPE>(url: string, options?: HttpOptions) => Observable<RESPONSE_TYPE>
export type HTTP_POST = <BODY_TYPE, RESPONSE_TYPE>(url: string, body: BODY_TYPE, options?: HttpOptions) => Observable<RESPONSE_TYPE>
export type HTTP_DELETE = (url: string, options?: HttpOptions) => Observable<void>

interface Http {
  GET: HTTP_GET;
  POST: HTTP_POST;
  DELETE: HTTP_DELETE;
}

export const useHttp = (): Http => {
  const auth = useAuth();
  const token = auth.user?.access_token;

  const withDefaults = (options: HttpOptions): HttpOptions => ({
    ...defaultHttpOptions,
    token,
    ...options,
  });
  
  const withDeleteDefaults = (options: HttpOptions): HttpOptions => ({
    ...defaultHttpOptions,
    accept: '--empty--',
    token,
    ...options,
  });

  return {
    GET: <RESPONSE_TYPE>(url: string, options: HttpOptions = {}): Observable<RESPONSE_TYPE> =>
        request('GET', url, withDefaults(options)),
    POST: <BODY_TYPE, RESPONSE_TYPE>(url: string, body: BODY_TYPE, options: HttpOptions = {}): Observable<RESPONSE_TYPE> =>
        request('POST', url, withDefaults(options), body),
    DELETE: (url: string, options: HttpOptions = {}): Observable<void> =>
        request('DELETE', url, withDeleteDefaults(options)),
  };
};

function readResponseBody(options: HttpOptions, r: Response) {
  if (options.accept === '--empty--') {
    return of(void 0);
  } else if (options.accept === 'application/json') {
    return from(r.json());
  } else if (options.accept === 'application/octet-stream') {
    return (from(r.arrayBuffer()));
  } else {
    return throwError(() => new Error('Unsupported media type in http.ts'));
  }
}

const request = <BODY_TYPE, RESPONSE_TYPE>(
    method: string,
    url: string,
    options: HttpOptions,
    body?: BODY_TYPE,
): Observable<RESPONSE_TYPE> => {
  return from(fetch(url, {
    method,
    headers: {
      ...authHeader(options.token),
      ...securityHeaders,
      ...acceptHeaders(options.accept),
      'Content-Type': 'application/json',
    },
    body: !!body ? JSON.stringify(body) : undefined,
  })).pipe(
      mergeMap(r => {
        if (r.ok) {
          return readResponseBody(options, r);
        } else {
          return throwError(() => new Error(r.statusText));
        }
      }),
  );
};
