import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface UnsortedListProps {
  className?: string;
  children: ReactNode;
}

export const UnsortedList: React.FC<UnsortedListProps> = ({ className, children }: UnsortedListProps) => {
  return (
      <ul className={ classNames('block list-style-disc', className) }>
        { children }
      </ul>
  );
};