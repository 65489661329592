import React from 'react';
import { Helmet } from 'react-helmet';
import './App.scss';
import { AppRouter } from './AppRouter';

const App = React.memo(() => (
    <>
      <Helmet titleTemplate="%s - Schwimmschule Schulz" defaultTitle="Schwimmschule Schulz">
        <meta name="description" content="Schwimmschule Schulz - Schwimmen lernen in Winsen" />
      </Helmet>
      <AppRouter/>
    </>
));

export default App;
