import React, { ReactNode } from 'react';

interface SortedListProps {
  type?: '1' | 'a' | 'A' | 'i' | 'I'
  children: ReactNode;
}

export const SortedList: React.FC<SortedListProps> = ({ type = '1', children }: SortedListProps) => {
  return (
      <ol className="block" type={type}>
        { children }
      </ol>
  );
};